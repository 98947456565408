import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import { sidebarMap } from "../../../constants/constants";
import {
  adminReApproval,
  adminReApprovalOtp,
  getConsultantProfile,
  inactivePartner,
} from "../../../api/partner";
import { BDetails, BLeaders } from "../../../types";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import SideBarB2A2 from "../../../components/SideBarB2A_2";

interface ConsultantProfileProps {
  permissions: Record<number, number[]> | undefined;
}

const ConsultantProfile: React.FC<ConsultantProfileProps> = ({
  permissions,
}: ConsultantProfileProps) => {
  const [active, setActive] = useState(sidebarMap.PARTNER_PROFILE);
  const [loading, setLoading] = useState<boolean>(false);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [showSelectAdmin, setShowSelectAdmin] = useState<boolean>(false);
  const [admins, setAdmins] = useState<BLeaders[]>();
  const [selectAdmin, setSelectAdmin] = useState<string>();
  const [sentEmail, setSentEmail] = useState<string>();
  const [otp, setOTP] = useState<string>("");
  const params = new URLSearchParams(window.location.search);
  let email = params.get("email") || undefined;
  let aid = params.get("aid") || "";

  const time = 300;
  const [seconds, setSeconds] = useState<number>(time);

  let timerId: NodeJS.Timeout | null = null; // To store the timer ID

  const startTimer = (initialSeconds: number) => {
    if (initialSeconds > 0) {
      timerId = setTimeout(() => {
        setSeconds(initialSeconds - 1);
        startTimer(initialSeconds - 1); // Call startTimer recursively
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (timerId) {
      clearTimeout(timerId); // Clear the timeout
      timerId = null; // Reset the timer ID
    }
  };

  const [details, setDetails] = useState<BDetails>();

  const [trigger, setTrigger] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleInactive = async () => {
    // add confirm
    if (
      window.confirm("Are you sure you want to mark this partner as inactive?")
    ) {
      setLoading(true);

      const response = await inactivePartner(aid);

      if (response?.status === 200) {
        alert("Partner Marked Inactive");
        setTrigger(!trigger);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getConsultantProfile(aid);
      if (response) {
        let blocks = response?.data?.data.detail;
        setDetails(blocks);
        setAdmins(response?.data?.data.leaders);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, aid, trigger]);

  const consultantReApprovalOtp = async () => {
    setLoading(true);
    stopTimer();

    if (selectAdmin) {
      const response = await adminReApprovalOtp({ admin: selectAdmin });
      if (response.status === 200) {
        let blocks = response?.data?.data.email;

        setSentEmail(blocks);
        setShowOtp(true);
        setShowSelectAdmin(false);
        startTimer(300);
        setOTP("");
      }
    }

    setLoading(false);
  };

  const consultantReApproval = async () => {
    setLoading(true);

    if (otp && otp.length === 6) {
      const response = await adminReApproval({
        e_otp: otp,
        admin: selectAdmin,
        partner_aid: aid,
      });
      if (response.status === 200) {
        setShowOtp(false);
        stopTimer();

        alert("Partner Marked Active");
        window.location.reload();
      }
    }

    setLoading(false);
  };

  return (
    <div>
      <SideBarB2A2
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="breadcrumb">
            <span
              className="track_lead_bread"
              onClick={() => {
                navigate("/m_partner");
              }}
            >
              Manage Consultants
            </span>
            <img
              src="https://cdn.pegasus.imarticus.org/pgBandF/ArrowBlack.svg"
              alt="profile"
              style={{ width: "4px" }}
              className="ml-1"
            />
            <span className="ml-1 partnerName">
              {details && details.p_name && details.p_name}
            </span>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div>
              {showSelectAdmin && (
                <div className="popupModal ">
                  <div className="popupModal_main " style={{ width: "350px" }}>
                    <div className="popupModal_header">
                      <span></span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowSelectAdmin(false);
                        }}
                      />
                    </div>
                    <div className="popupModal_body center-align">
                      <span className="mt-1 select_adm_head">
                        Select Admin for OTP Approval
                      </span>

                      <select
                        onChange={(e) => {
                          setSelectAdmin(e.target.value);
                        }}
                        className="select_adm_select"
                      >
                        <option> Select Admin </option>

                        {admins && admins?.length > 0 && (
                          <>
                            {admins.map((elem, i) => {
                              return (
                                <option value={elem.aid} key={i}>
                                  {elem.p_name}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>

                      {/* <div className="footOTP" style={{ color: "#E56874" }}>
                        {error}
                      </div> */}

                      <button
                        disabled={!selectAdmin}
                        onClick={() => {
                          consultantReApprovalOtp();
                        }}
                        className="pm_create w-50 mt-1 mb-3 disable_btn"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showOtp && (
                <div className="popupModal ">
                  <div className="popupModal_main " style={{ width: "350px" }}>
                    <div className="popupModal_header">
                      <span></span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowOtp(false);
                        }}
                      />
                    </div>
                    <div className="popupModal_body center-align">
                      <img
                        src="https://cdn.pegasus.imarticus.org/b2b/OTP.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                      />
                      <span className="mt-1">OTP is valid upto 30 min</span>
                      <input
                        type="number"
                        className="popupModal_input w-50 mt-1 text-center"
                        required={true}
                        value={otp}
                        placeholder="0-0-0-0-0-0"
                        onChange={(e) => {
                          setOTP(e.target.value);
                        }}
                      />

                      <div className="footOTP" style={{ marginTop: "2rem" }}>
                        {seconds && seconds > 0 ? (
                          <div className="timer">
                            Resend in: {Math.floor(seconds / 60)}:
                            {10 > seconds - Math.floor(seconds / 60) * 60 &&
                              `0`}
                            {seconds - Math.floor(seconds / 60) * 60}
                          </div>
                        ) : (
                          <div className="timer">
                            <button
                              className="resendOtpBtn"
                              onClick={() => {
                                setSeconds(time);
                                consultantReApprovalOtp();
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                        )}
                      </div>
                      {/* 
                      <div className="footOTP" style={{ color: "#E56874" }}>
                        {error}
                      </div> */}

                      <div className="footOTP ">
                        OTP has been sent on email: <br />
                        {sentEmail}
                      </div>

                      <button
                        disabled={otp?.length < 6}
                        onClick={() => {
                          consultantReApproval();
                        }}
                        className="pm_create w-50 mt-1 mb-3 disable_btn"
                      >
                        Verify OTP
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {details && (
                <div className="consultantDetails">
                  <div className="cdHead">Basic Details</div>
                  <hr />

                  <div>
                    <div className="popupModal_form">
                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Full Name <span className="red">*</span>
                        </span>
                        <input
                          type="text"
                          className="popupModal_input"
                          value={details.p_name}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Email ID <span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.p_email}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Mobile Number<span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.p_mobile}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          State <span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.state}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields">
                        <span className="popupModal_label">
                          Location<span className="red">*</span>
                        </span>
                        <input
                          type="email"
                          className="popupModal_input"
                          value={details.location}
                          disabled
                        />
                      </div>

                      <div className="popupModal_fields"></div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-1"></div>
              {details && details.status === 1 ? (
                <div className="inact">
                  <button
                    className="inact_btn"
                    onClick={() => {
                      handleInactive();
                    }}
                  >
                    Mark as Inactive
                  </button>
                </div>
              ) : (
                <div className="inact">
                  <div></div>
                  <button
                    className="inact_btn act_btn"
                    onClick={() => {
                      setShowSelectAdmin(true);
                    }}
                  >
                    Mark as Active
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultantProfile;
