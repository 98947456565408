import TopBar from "../../components/Topbar";
import {
  PARTNER_OPS_ROLES,
  RESOURCES,
  RESOURCES_PERMISSION,
  sidebarMap,
} from "../../constants/constants";
import SideBar4 from "../../components/Sidebar4";
import { useEffect, useState } from "react";
import {
  createopsUser,
  deleteOpsUser,
  editSG,
  getAllUsers,
  getuserDetails,
  vrfyUserOpsEmail,
} from "../../api/partner";
import Loader from "../../components/Loader";
import {
  FormControl,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";

import { getDate } from "../../utility/helper";
import { SalesGroup, User } from "../../types";

interface UserPermissionProps {
  permissions: Record<number, number[]> | undefined;
}

const UserPermission: React.FC<UserPermissionProps> = ({
  permissions,
}: UserPermissionProps) => {
  const active = sidebarMap.USER_PERMISSION;

  const [loading, setLoading] = useState<boolean>(false);

  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [users, setUsers] = useState<User[]>([]);
  const [qRole, setqRole] = useState<string>("");
  const [allRoles, setAllRoles] = useState<string[]>([]);
  const [create, setCreate] = useState<boolean>();

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [uid, setUID] = useState<string>("");
  const [aid, setAid] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [permissionMap, setPermissionMap] = useState<any>();

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const [selectSg, setSelectSG] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectSg>) => {
    const {
      target: { value },
    } = event;

    setSelectSG(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getAllUsers({ rowsPerPage, page, qRole });
      if (response) {
        const value = response?.data?.data;

        setUsers(value?.users);
        setTotalCount(value?.totalusers);
        setAllRoles(value?.allRoles);
        setAllSgs(value.sgNames);
        setPermissionMap(value?.resource_by_role);
      }

      setLoading(false);
    };

    getPartners();
  }, [page, rowsPerPage, qRole, trigger]);

  const vrfyUser = async () => {
    if (email.trim()) {
      setLoading(true);
      const response = await vrfyUserOpsEmail({
        email: email.trim().toLowerCase(),
      });
      if (response) {
        const value = response?.data?.data;
        setName(value.name);
        setUID(value.uid);
        setAid(value?.aid);
      }
    }
    setLoading(false);
  };

  const empty = () => {
    setName("");
    setUID("");
    setAid("");
    setEmail("");
    setRole("");
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !name?.trim() ||
      !email?.trim() ||
      (role !== PARTNER_OPS_ROLES.SUPER_ADMIN &&
        role !== PARTNER_OPS_ROLES.B2A_ADMIN &&
        role !== PARTNER_OPS_ROLES.B2A_TEAM_LEADER &&
        !(selectSg.length > 0))
    ) {
      alert("All fields are mandatory");
      return;
    } else {
      setLoading(true);
      const response = await createopsUser({
        user_aid: aid,
        role: role,
        sales_grp: selectSg,
      });

      if (response?.status === 200) {
        alert("Partner Created!");
        empty();
        setCreate(false);
        setTrigger(!trigger);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  const handleDelete = async (aid: string) => {
    if (window.confirm("Are you sure you want to delete the user?")) {
      setLoading(true);
      const response = await deleteOpsUser({
        user_aid: aid,
      });

      if (response?.status === 200) {
        alert("Partner Deleted!");
        empty();
        setCreate(false);
        setTrigger(!trigger);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  const handleDetails = async (aid: string) => {
    setLoading(true);
    const response = await getuserDetails(aid);

    if (response?.status === 200) {
      setEdit(true);
      const value = response?.data?.data;
      setEmail(value.checkAcc.ep);
      setName(value.checkProfile?.nam);
      setRole(value.checkRole.rname);
      setAid(aid);
      setSelectSG(value.sgs);
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }
    setLoading(false);
  };

  const editHandler = async () => {
    setLoading(true);
    const response = await editSG(aid, selectSg);

    if (response?.status === 200) {
      setEdit(false);
      alert("Sales Group Edited");
      setTrigger(!trigger);
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <SideBar4 />
          <TopBar active={active} />
          <div className="main">
            <>
              {edit && (
                <div className="popupModal">
                  <div
                    className="popupModal_main"
                    style={{ maxHeight: "80vh", overflowY: "scroll" }}
                  >
                    <div className="popupModal_header">
                      <span>Edit Sales Group</span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEdit(false);
                          empty();
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <form onSubmit={editHandler}>
                        <div className="popupModal_form">
                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Email Id
                              <span className="red">*</span>
                            </span>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="popupModal_input"
                              disabled={true}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              User Name <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              value={name}
                              disabled
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>

                          {/* <div className="popupModal_fields">
                            <span className="popupModal_label">
                              User UID <span className="red">*</span>
                            </span>

                            <input
                              type="text"
                              className="popupModal_input"
                              value={uid}
                              onChange={(e) => {
                                setUID(e.target.value);
                              }}
                              disabled
                              required={true}
                            />
                          </div> */}

                          <div
                            className="popupModal_fields"
                            style={{ width: "92%" }}
                          >
                            <span className="popupModal_label">
                              Role Type <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              disabled={true}
                              value={role}
                              required={true}
                              onChange={(e) => {
                                setRole(e.target.value);
                              }}
                            >
                              <option value="">Select Role</option>
                              {allRoles &&
                                allRoles.map((elem, i) => {
                                  if (
                                    elem === "Partner" ||
                                    elem === "Applicant" ||
                                    elem === "Consultant"
                                  ) {
                                    return <></>;
                                  }

                                  return <option value={elem}>{elem}</option>;
                                })}
                            </select>
                          </div>

                          {(role === PARTNER_OPS_ROLES.AUDIT_ADMIN ||
                            role === PARTNER_OPS_ROLES.FINANCE_MEMBER ||
                            role === PARTNER_OPS_ROLES.PARTNER_TEAM_LEADERS ||
                            role ===
                              PARTNER_OPS_ROLES.PARTNER_TEAM_LEADERS) && (
                            <div
                              className="popupModal_fields"
                              style={{ width: "95%", marginLeft: "28px" }}
                            >
                              <span className="popupModal_label">
                                Sales Group <span className="red">*</span>{" "}
                                <Tooltip title="Partner and student leads will be visible to assigned sales group only">
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                                    alt="profile"
                                    style={{ width: "20px" }}
                                  />
                                </Tooltip>
                              </span>
                              <FormControl sx={{ width: "95%" }}>
                                <Select
                                  sx={{ height: "42px", fontSize: "12px" }}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  className="custom_sg_select"
                                  multiple
                                  value={selectSg}
                                  disabled={aid ? false : true}
                                  onChange={handleChange}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                >
                                  {allSgs.map((elem, i) => (
                                    <MenuItem key={i} value={elem.name}>
                                      <ListItemText primary={elem.name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                          <div className="popupModal_fields"></div>
                        </div>
                        <div className="popupModal_foot">
                          <button
                            className="pm_create pm_cancel"
                            onClick={() => {
                              setEdit(false);
                            }}
                          >
                            Cancel
                          </button>
                          {aid && (
                            <button className="pm_create" type="submit">
                              Edit
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              {create && (
                <div className="popupModal">
                  <div
                    className="popupModal_main"
                    style={{ maxHeight: "80vh", overflowY: "scroll" }}
                  >
                    <div className="popupModal_header">
                      <span>Assing Roles To Users</span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCreate(false);
                          empty();
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <form onSubmit={submitHandler}>
                        <div className="popupModal_form">
                          <div className="popupModal_fields-3">
                            <div className="popupModal_fields-2">
                              <span className="popupModal_label">
                                Email Id
                                <span className="red">*</span>
                              </span>
                              <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="popupModal_input"
                                disabled={aid ? true : false}
                              />
                            </div>
                            <div className="eye_div">
                              <div
                                className="vrfy_btn"
                                onClick={() => {
                                  vrfyUser();
                                }}
                              >
                                Verify
                              </div>
                            </div>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              User Name <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              value={name}
                              disabled
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              User UID <span className="red">*</span>
                            </span>

                            <input
                              type="text"
                              className="popupModal_input"
                              value={uid}
                              onChange={(e) => {
                                setUID(e.target.value);
                              }}
                              disabled
                              required={true}
                            />
                          </div>

                          <div
                            className="popupModal_fields"
                            style={{ width: "92%" }}
                          >
                            <span className="popupModal_label">
                              Select Role Type <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              // disabled={aid ? false : true}
                              value={role}
                              required={true}
                              onChange={(e) => {
                                setRole(e.target.value);
                              }}
                            >
                              <option value="">Select Role</option>
                              {allRoles &&
                                allRoles.map((elem, i) => {
                                  if (
                                    elem === "Partner" ||
                                    elem === "Applicant" ||
                                    elem === "Consultant"
                                  ) {
                                    return <></>;
                                  }

                                  return <option value={elem}>{elem}</option>;
                                })}
                            </select>
                          </div>

                          {role && (
                            <div className="permissionMaping">
                              <span className="popupModal_label">
                                View Permissions <span className="red">*</span>
                              </span>
                              <TableContainer
                                component={Paper}
                                style={{
                                  width: "100%",
                                  marginTop: "1rem",
                                }}
                              >
                                <Table>
                                  <TableBody>
                                    {role &&
                                      permissionMap &&
                                      permissionMap[role].map(
                                        (elem: any, i: any) => {
                                          return (
                                            <TableRow
                                              className="table_row_pm"
                                              key={i}
                                            >
                                              <div className="pmHead">
                                                {elem &&
                                                  elem.map(
                                                    (elem2: any, i: any) => {
                                                      return (
                                                        <>
                                                          {i === 0 ? (
                                                            <TableCell className="pmr">
                                                              <span>
                                                                {
                                                                  RESOURCES[
                                                                    elem2
                                                                  ]
                                                                }
                                                              </span>
                                                            </TableCell>
                                                          ) : (
                                                            <TableCell
                                                              align="center"
                                                              style={{
                                                                minWidth:
                                                                  "100px",
                                                              }}
                                                            >
                                                              <span className="pmr2">
                                                                {
                                                                  RESOURCES_PERMISSION[
                                                                    elem2
                                                                  ]
                                                                }
                                                              </span>
                                                            </TableCell>
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          )}

                          {(role === PARTNER_OPS_ROLES.AUDIT_ADMIN ||
                            role === PARTNER_OPS_ROLES.FINANCE_MEMBER ||
                            role === PARTNER_OPS_ROLES.PARTNER_TEAM_LEADERS ||
                            role ===
                              PARTNER_OPS_ROLES.PARTNER_TEAM_LEADERS) && (
                            <div
                              className="popupModal_fields"
                              style={{ width: "95%", marginLeft: "28px" }}
                            >
                              <span className="popupModal_label">
                                Sales Group <span className="red">*</span>{" "}
                                <Tooltip title="Partner and student leads will be visible to assigned sales group only">
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                                    alt="profile"
                                    style={{ width: "20px" }}
                                  />
                                </Tooltip>
                              </span>
                              <FormControl sx={{ width: "95%" }}>
                                <Select
                                  sx={{ height: "42px", fontSize: "12px" }}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  className="custom_sg_select"
                                  multiple
                                  value={selectSg}
                                  disabled={aid ? false : true}
                                  onChange={handleChange}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                >
                                  {allSgs.map((elem, i) => (
                                    <MenuItem key={i} value={elem.name}>
                                      <ListItemText primary={elem.name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                          <div className="popupModal_fields"></div>
                        </div>
                        <div className="popupModal_foot">
                          {aid && (
                            <button className="pm_create" type="submit">
                              Add
                            </button>
                          )}
                          <button
                            className="pm_create pm_cancel"
                            onClick={() => {
                              setCreate(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              <div className="filters " style={{ marginTop: "0" }}>
                <div>
                  <div className="filter_headers">Select Partner Role</div>
                  <select
                    className="filter_inputs"
                    value={qRole}
                    onChange={(e) => {
                      setqRole(e.target.value);
                    }}
                  >
                    <option value="">All</option>

                    {allRoles &&
                      allRoles.map((elem, i) => {
                        return <option value={elem}>{elem}</option>;
                      })}
                  </select>
                </div>

                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="partner_leads_header">
                Roles & Permission Panel
                <div>
                  <button
                    onClick={() => {
                      setCreate(true);
                    }}
                    className="back_btn col-green"
                  >
                    + Add New User
                  </button>
                </div>
              </div>
              <hr />

              {users && users.length > 0 ? (
                <div>
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 75, 250, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    <Table>
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          S No.
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          UID
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Phone
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Role Type
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Partner Type
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Parent Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Sales Group
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Joined On
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Action
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {users.length > 0 &&
                          users.map((elem, i) => {
                            return (
                              <TableRow className="table_row" key={i}>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {rowsPerPage * page + i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_uid}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_name}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_mobile}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.role}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_type ? elem.p_type : "-"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.parent_email ? elem.parent_email : "-"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.sg && elem.sg.length > 0 ? (
                                    <>
                                      {elem.sg.map((elem, i) => {
                                        return (
                                          <div key={i} className="sg_users">
                                            {elem}
                                            <br />
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.details?.status === 2
                                    ? "Inactive"
                                    : "Active"}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {getDate(elem.createdAt)}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  <div className="">
                                    {elem.role !==
                                      PARTNER_OPS_ROLES.APPLICANT &&
                                      elem.role !== PARTNER_OPS_ROLES.PARTNER &&
                                      elem.role !==
                                        PARTNER_OPS_ROLES.CONSULTANT &&
                                      elem.role !==
                                        PARTNER_OPS_ROLES.B2A_ADMIN &&
                                      elem.role !==
                                        PARTNER_OPS_ROLES.B2A_TEAM_LEADER && (
                                        <>
                                          {/* <button
                                            className="editBTN"
                                            onClick={() => {
                                              handleDetails(elem.aid);
                                            }}
                                          >
                                            <img
                                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/pencil.svg"
                                              alt="edit"
                                            />
                                          </button> */}

                                          <button
                                            className="table_edit table_view"
                                            onClick={() => {
                                              handleDetails(elem.aid);
                                            }}
                                          >
                                            <img
                                              src="https://cdn.pegasus.imarticus.org/partnerimarticus/pencil2.svg"
                                              alt="bin"
                                            />
                                          </button>
                                        </>
                                      )}

                                    {elem.role !==
                                      PARTNER_OPS_ROLES.APPLICANT &&
                                      elem.role !== PARTNER_OPS_ROLES.PARTNER &&
                                      elem.role !==
                                        PARTNER_OPS_ROLES.CONSULTANT && (
                                        // <button
                                        //   className="bin"
                                        //   onClick={() => {
                                        //     handleDelete(elem.aid);
                                        //   }}
                                        // >
                                        //   <img
                                        //     src="https://cdn.pegasus.imarticus.org/partnerimarticus/bin.svg"
                                        //     alt="bin"
                                        //   />
                                        // </button>
                                        <button
                                          className="table_edit table_del"
                                          onClick={() => {
                                            handleDelete(elem.aid);
                                          }}
                                        >
                                          <img
                                            src="https://cdn.pegasus.imarticus.org/partnerimarticus/bin.svg"
                                            alt="bin"
                                          />
                                        </button>
                                      )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">
                    Click on Create Partner to map the existing leads with a
                    partner
                  </span>
                </div>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default UserPermission;
