import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import {
  leadStages,
  REQ_STATUS,
  sidebarMap,
  UPLOAD_STATUS,
} from "../../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  createNewLead,
  getCRequests,
  getProgramList,
  getRequestedLeads,
  uploadLeads,
} from "../../../api/partner";
import moment from "moment-timezone";

import { LeadRequests, ProgramList, SingleRequestLeads } from "../../../types";
import Loader from "../../../components/Loader";
import { getDate } from "../../../utility/helper";

import SideBarB2AC from "../../../components/SideBarB2A_C";
import { CountryCodes } from "../../../utility/utils";

interface UploadLeadsProps {
  permissions: Record<number, number[]> | undefined;
}

const UploadLeads: React.FC<UploadLeadsProps> = ({
  permissions,
}: UploadLeadsProps) => {
  const [active, setActive] = useState(sidebarMap.UPLOAD_LEADS);
  const [loading, setLoading] = useState<boolean>(false);

  const [bulk, setBulk] = useState<boolean>(false);
  const [bulkInfo, setBulkInfo] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [ccod, setCcod] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [program, setProgram] = useState<string>("");
  const [stage, setStage] = useState<string>("");

  const [requests, setRequests] = useState<LeadRequests[]>();
  const [sLeads, setsLeads] = useState<SingleRequestLeads[]>();

  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [trigger, setTrigger] = useState<boolean>(false);
  const [programList, setProgramList] = useState<ProgramList[]>([]);
  const [qprogram, setqProgram] = useState<string>("");
  const [owner, setOwner] = useState<string>();

  const [qstatus, setQstatus] = useState<string>();
  const [qUploadStatus, setQUploadStatus] = useState<string>();

  const [single, setSingle] = useState<boolean>(false);

  const [singleLeads, setSingleLeads] = useState<boolean>(false);

  const [tSuccess, setTSuccess] = useState<number>(0); // Total number of rows
  const [tFailed, setTFailed] = useState<number>(0); // Total number of rows

  const [totalCount1, setTotalCount1] = useState<number>(0); // Total number of rows
  const [page1, setPage1] = useState<number>(0); // Current page number
  const [rowsPerPage1, setRowsPerPage1] = useState<number>(10);
  const [activeRequest, setActiveRequest] = useState<string>("");

  const [totalLeads, setToalLeads] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const [file, setFile] = useState("");

  const handleFile = (e: any) => {
    let file = e.target.files[0];
    if (file === undefined) return;
    setFile(file);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage1: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0); // Reset page number when rows per page changes
    getSingleRequestLeads(activeRequest, "0", parseInt(event.target.value, 10));
  };

  const handleChangePage1 = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage1(newPage);
    getSingleRequestLeads(activeRequest, newPage, rowsPerPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getCRequests({
        rowsPerPage,
        page,
        qprogram,
        sDate,
        eDate,
        qstatus,
        qUploadStatus,
        search,
        single: "*",
      });
      if (response) {
        const value = response?.data?.data;
        setToalLeads(value?.totalDraftLeads);
        setRequests(value?.requests);
        setTotalCount(value?.totalRequests);
        setTFailed(value?.totalFailed);
        setTSuccess(value?.totalSuccess);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, eDate, trigger, qprogram, qstatus, qUploadStatus]);

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const response = await getProgramList();
      if (response) {
        const value = response?.data?.data;
        setProgramList(value.programs);
      }

      setLoading(false);
    };

    getPartners();
  }, []);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !name?.trim() ||
      !email?.trim() ||
      !ccod?.trim() ||
      !mobile?.trim() ||
      !stage?.trim()
    ) {
      alert("All fields are mandatory");
      return;
    } else {
      setLoading(true);
      const response = await createNewLead({
        name,
        email,
        ccod,
        mobile,
        program,
        stage,
      });

      if (response?.status === 200) {
        setTrigger(!trigger);
        empty();
        setSingle(false);
        setSuccess(true);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  const empty = () => {
    setName("");
    setEmail("");
    setCcod("");
    setMobile("");
    setStage("");
    setProgram("");
  };

  const getSingleRequestLeads = async (
    id: string,
    page: any,
    rowsPerPage: number
  ) => {
    setLoading(true);

    const response = await getRequestedLeads(
      id,
      rowsPerPage || rowsPerPage1,
      page || page1
    );

    if (response?.status === 200) {
      const value = response.data.data;
      setSingleLeads(true);
      setActiveRequest(id);
      setsLeads(value.leads);
      setTotalCount1(value.totalLeads);
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }

    setLoading(false);
  };

  const downloadCSV = () => {
    const csvData = [
      {
        Name: "sample name",
        Email: "sample@email.com",
        "Country Code": "+91",
        Mobile: "99999999999",
        Stage: "sample",
      },
    ];
    const header = "Name,Email,Country Code,Mobile,Stage\n";
    const rows = csvData
      .map(
        (obj) =>
          `${obj["Name"]},${obj["Email"]},${obj["Country Code"]},${obj["Mobile"]},${obj["Stage"]}`
      )
      .join("\n");
    const csvContent = header + rows;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const uploadBulk = async () => {
    console.log(file);
    if (!file) {
      alert("Please choose a file");
      return;
    } else if (!program) {
      alert("Please choose a program");
      return;
    } else {
      setLoading(true);

      const data = new FormData();
      data.append("file", file);
      data.append("program", program);

      const response = await uploadLeads(data);

      if (response?.status === 200) {
        setBulk(false);
        setBulkInfo(false);
        setTrigger(!trigger);
        setSuccess(true);
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <SideBarB2AC
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          {loading ? (
            <Loader />
          ) : (
            <>
              {success && (
                <div className="errBox_container">
                  <div className="errBox_container_box">
                    <div className="errBox_main">
                      <img
                        src="https://cdn.pegasus.imarticus.org/partnerimarticus/tick2.svg"
                        alt="logo"
                        style={{ width: "50px" }}
                      />
                      <div className="errBox_Head mt-1">
                        Lead successfully uploaded
                      </div>

                      <div className="errBox_btn">
                        <button
                          className="err_yes"
                          onClick={() => {
                            setSuccess(false);
                          }}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bulk && (
                <div className="popupModal">
                  <div className="popupModal_main_2">
                    <div className="popupModal_header">
                      <span>
                        Upload Leads{" "}
                        <Tooltip
                          title="Click for more information"
                          onClick={() => {
                            setBulkInfo(true);
                            setFile("");
                          }}
                        >
                          <img
                            src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                            alt="profile"
                            className="ml-1"
                          />
                        </Tooltip>{" "}
                      </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBulk(false);
                          setFile("");
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <div className="popupModal_form_2">
                        <div className="popupModal_fields-2">
                          <span className="popupModal_label">
                            Select Program <span className="red">*</span>
                          </span>

                          <select
                            className="popupModal_input "
                            value={program}
                            onChange={(e) => {
                              setProgram(e.target.value);
                            }}
                            required={true}
                          >
                            <option value="">Select Program</option>
                            {programList &&
                              programList.map((elem, i) => {
                                return (
                                  <option
                                    value={elem.leadsquareProgramKey}
                                    key={i}
                                  >
                                    {elem.leadsquareProgramKey}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="popupModal_fields-2">
                          <input
                            type="file"
                            className="popupModal_input fileInput"
                            onChange={handleFile}
                            accept=".csv"
                          />
                        </div>

                        <div className="popupModal_fields-2">
                          <button
                            className="uploadPartners"
                            onClick={() => {
                              uploadBulk();
                            }}
                          >
                            Upload Multiple Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bulkInfo && (
                <div className="popupModal">
                  <div className="popupModal_main">
                    <div className="popupModal_header">
                      <span>
                        Expected format of the Leads File to be uploaded
                      </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBulkInfo(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <div className="infoBlock">
                        <div className="infoRow">
                          <span>
                            1. The File has to be in the CSV format only.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>

                        <div className="infoRow">
                          <span>2. Download the sample file from here.</span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              downloadCSV();
                              alert("Downloaded");
                            }}
                          >
                            Download Sample
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            3. An entry of any user may have the following
                            columns:
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>
                        <div className="infoRow">
                          <span>&nbsp; &nbsp; &nbsp; a. 'Name' (Required)</span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Name");

                              alert("Copied Name");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; b. 'Email' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Email");

                              alert("Copied Email");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; c. 'Country Code' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Country Code");

                              alert("Copied Country Code");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; d. 'Mobile' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("Mobile");

                              alert("Copied Mobile ");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            &nbsp; &nbsp; &nbsp; e. 'Stage' (Required)
                          </span>
                          <button
                            className="infobtns"
                            onClick={() => {
                              navigator.clipboard.writeText("State");

                              alert("Copied State");
                            }}
                          >
                            Copy
                          </button>
                        </div>

                        <div className="infoRow">
                          <span>
                            4. If the file is not in the proper format, a proper
                            import request will not be generated.
                          </span>
                        </div>

                        <div className="infoRow">
                          <span>
                            5. The maximum numbers of Partners in 1 request is
                            limited to 50.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>

                        <div className="infoRow">
                          <span>
                            6. Multiple Partners can be generated by uploading
                            the CSV file in the provided format and clicking the
                            "Upload Multiple Requests" button.
                          </span>
                          {/* <button className="infobtns">Download Sample</button> */}
                        </div>
                      </div>

                      <div className="popupModal_foot">
                        <button
                          onClick={() => {
                            setBulkInfo(false);
                          }}
                          className="pm_create"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {singleLeads && (
                <div className="popupModal">
                  <div
                    className="popupModal_main"
                    style={{
                      maxHeight: "80vh",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="popupModal_header">
                      <span>All Leads </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSingleLeads(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div
                      className="popupModal_body"
                      style={{
                        width: "90%",
                        padding: "1rem",

                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 150]}
                        component="div"
                        count={totalCount1}
                        rowsPerPage={rowsPerPage1}
                        page={page1}
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage1}
                      />
                      <TableContainer component={Paper}>
                        <Table className="table_p">
                          <TableHead className="table_head">
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              S.No
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Mobile No.
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Lead Stage
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Approval Status
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              Lead Import Status
                            </TableCell>
                            {/* <TableCell className="trb" align="center" style={{ minWidth: "100px" }}>
                              Remarks
                            </TableCell> */}
                          </TableHead>
                          <TableBody>
                            {sLeads &&
                              sLeads.map((elem, i) => {
                                return (
                                  <TableRow className="table_row">
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {" "}
                                      {rowsPerPage * page + i + 1}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.firstName}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.email}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {getDate(elem.mobile)}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.stage}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        elem.status === 1
                                          ? "trb pending_yellow"
                                          : elem.status === 2
                                          ? "trb approve_green"
                                          : "trb flagged_red"
                                      }
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {REQ_STATUS[elem.status]}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        elem.upload_status === 1
                                          ? "trb pending_grey"
                                          : elem.upload_status === 2
                                          ? "trb approve_green"
                                          : elem.upload_status === 3
                                          ? "trb flagged_red"
                                          : "trb pending_yellow"
                                      }
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {UPLOAD_STATUS[elem.upload_status]}
                                    </TableCell>
                                    {/* <TableCell className="trb" align="center" style={{ minWidth: "100px" }}>
                                      {elem.remarks ? elem.remarks : "-"}
                                    </TableCell> */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              )}

              {single && (
                <div className="popupModal">
                  <div className="popupModal_main">
                    <div className="popupModal_header">
                      <span>Create Partner </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSingle(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div className="popupModal_body">
                      <form onSubmit={submitHandler}>
                        <div className="popupModal_form">
                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Lead Full Name <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Enter Email ID <span className="red">*</span>
                            </span>
                            <input
                              type="email"
                              className="popupModal_input"
                              required={true}
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>

                          <div className="popupModal_fields popupModal_ccod_wrapper">
                            <span className="popupModal_label">
                              Enter Mobile Number <span className="red">*</span>
                            </span>
                            <div
                              className="popupModal_ccod"
                              style={{ background: "#fff" }}
                            >
                              <select
                                className="popupModal_input ccod_input"
                                value={ccod}
                                onChange={(e) => {
                                  setCcod(e.target.value);
                                }}
                                required={true}
                              >
                                <option value="">Country</option>
                                {CountryCodes &&
                                  CountryCodes.map((elem, i) => {
                                    return (
                                      <option key={i} value={elem.value}>
                                        {elem.display}
                                      </option>
                                    );
                                  })}
                              </select>

                              <input
                                type="number"
                                required={true}
                                value={mobile}
                                maxLength={10}
                                minLength={10}
                                className="popupModal_input mob_input"
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Select Program Domain{" "}
                              <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={program}
                              onChange={(e) => {
                                setProgram(e.target.value);
                              }}
                              required={true}
                            >
                              <option value="">Select Program</option>
                              {programList &&
                                programList.map((elem, i) => {
                                  return (
                                    <option
                                      value={elem.leadsquareProgramKey}
                                      key={i}
                                    >
                                      {elem.leadsquareProgramKey}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Lead Source <span className="red">*</span>
                            </span>
                            <input
                              type="text"
                              className="popupModal_input"
                              required={true}
                              disabled
                              value={"B2A Consultant"}
                            />
                          </div>

                          <div className="popupModal_fields">
                            <span className="popupModal_label">
                              Select Lead Stage <span className="red">*</span>
                            </span>

                            <select
                              className="popupModal_input "
                              value={stage}
                              onChange={(e) => {
                                setStage(e.target.value);
                              }}
                              required={true}
                            >
                              <option value="">Select Lead Stage</option>
                              {leadStages &&
                                leadStages.map((elem, i) => {
                                  return (
                                    <option value={elem} key={i}>
                                      {elem}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="popupModal_foot">
                          <button
                            className="pm_create pm_cancel"
                            onClick={() => {
                              //   setCreate(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button className="pm_create" type="submit">
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              <div className="filters filter-3" style={{ marginTop: "0" }}>
                <div>
                  <div className="filter_headers"> Select Course </div>
                  <select
                    onChange={(e) => {
                      setqProgram(e.target.value);
                    }}
                    value={qprogram}
                    className="filter_inputs"
                  >
                    <option value="">All</option>
                    {programList &&
                      programList.map((elem, i) => {
                        return (
                          <option value={elem.leadsquareProgramKey} key={i}>
                            {elem.leadsquareProgramKey}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  <div className="filter_headers">From Date</div>
                  <input
                    type="date"
                    className="filter_inputs"
                    value={sDate}
                    onChange={(e) => {
                      setsDate(e.target.value);
                      // getAllPartnerLeads();
                    }}
                  />
                </div>
                <div>
                  <div className="filter_headers">To Date</div>
                  <input
                    type="date"
                    className="filter_inputs"
                    value={eDate}
                    disabled={sDate ? false : true}
                    onChange={(e) => {
                      seteDate(e.target.value);
                      // getAllPartnerLeads();
                    }}
                  />
                </div>
              </div>

              <div className="stats">
                <div>
                  <div className="stats_head">Total Leads </div>
                  <div className="stats_stat">{totalLeads && totalLeads}</div>
                </div>

                <div>
                  <div className="stats_head">Total Successful Uploads</div>
                  <div className="stats_stat">{tSuccess && tSuccess}</div>
                </div>

                <div>
                  <div className="stats_head">Total Failed Uploads </div>
                  <div className="stats_stat">{tFailed && tFailed}</div>
                </div>
              </div>

              <div className="filter-3">
                <div>
                  <div className="filter_headers"> Lead Import Status</div>
                  <select
                    onChange={(e) => {
                      setQUploadStatus(e.target.value);
                    }}
                    value={qUploadStatus}
                    className="filter_inputs"
                  >
                    <option value="">All</option>
                    <option value="1">Pending</option>
                    <option value="2">Uploaded</option>
                    <option value="3">Not Uploaded</option>
                    <option value="4">Partial</option>
                  </select>
                </div>

                <div>
                  <div className="filter_headers"> Select Approval Status</div>
                  <select
                    onChange={(e) => {
                      setQstatus(e.target.value);
                    }}
                    value={qstatus}
                    className="filter_inputs"
                  >
                    <option value="">All</option>
                    <option value="1">Pending </option>
                    <option value="2">Approved</option>
                    <option value="3">Rejected</option>
                  </select>
                </div>
              </div>

              <div className="application_header mt-1">
                All Lead Request{" "}
                <Tooltip title="Here you can track all leads requests">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
                    alt="profile"
                    className="ml-1"
                  />
                </Tooltip>
              </div>
              <hr />

              <div className="search_newapp mt-1">
                <div className="flex">
                  <div>
                    <input
                      type="text"
                      className="search_filters ml-1 ph-1"
                      placeholder={`Enter Program`}
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />

                    {search && search.trim() && (
                      <button
                        className="form_back ml-1"
                        onClick={() => {
                          setTrigger(!trigger);
                        }}
                      >
                        Search
                      </button>
                    )}
                    <button
                      className="form_back ml-1"
                      onClick={() => {
                        setSearch("");

                        seteDate("");
                        setsDate("");
                        setTrigger(!trigger);
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="uploadAddBtns">
                  <div className="ml-1 ">
                    <button
                      className="uploadAddBtn darkbtn centeralign"
                      onClick={() => {
                        setBulk(true);
                      }}
                    >
                      <img
                        src="https://cdn.pegasus.imarticus.org/partnerimarticus/uploadSVG.svg"
                        alt="profile"
                      />{" "}
                      <span>Upload Bulk Leads</span>
                    </button>
                  </div>

                  <div className="ml-1">
                    <button
                      className="uploadAddBtn centeralign"
                      onClick={() => {
                        setSingle(true);
                      }}
                    >
                      <img
                        src="https://cdn.pegasus.imarticus.org/partnerimarticus/plus.svg"
                        alt="profile"
                      />{" "}
                      <span className="ml-1">Add Leads</span>
                    </button>
                  </div>
                </div>
              </div>
              {requests && requests.length > 0 ? (
                <div className="">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    <Table className="table_p">
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Program Domain
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Team Leader
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Source
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Created Time
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Number of Leads
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Approval Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Import Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Remarks
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {requests &&
                          requests.map((elem, i) => {
                            return (
                              <TableRow className="table_row">
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {rowsPerPage * page + i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.program}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.leader_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  B2A Consultant
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {moment
                                    .utc(elem.createdAt)
                                    .tz("Asia/Kolkata")
                                    .format("DD-MM-YY, HH:mm:ss")}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  <span
                                    onClick={async () => {
                                      await getSingleRequestLeads(
                                        elem._id,
                                        page1,
                                        rowsPerPage1
                                      );
                                    }}
                                    className="link"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {elem.count}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className={
                                    elem.status === 1
                                      ? "trb pending_yellow"
                                      : elem.status === 2
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {REQ_STATUS[elem.status]}
                                </TableCell>
                                <TableCell
                                  className={
                                    elem.upload_status === 1
                                      ? "trb pending_grey"
                                      : elem.upload_status === 2
                                      ? "trb approve_green"
                                      : elem.upload_status === 3
                                      ? "trb flagged_red"
                                      : "trb pending_yellow"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {UPLOAD_STATUS[elem.upload_status]}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.remarks ? elem.remarks : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">Oops! Nothing found here</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadLeads;
