import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getApplicantSideApplication,
  submitApplication,
  updateApplicationSlug,
  uploadFormFiles,
} from "../../api/partner";
import { Application, ApplicationInfos } from "../../types";

import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { logout } from "../../api/auth";
import Cookies from "universal-cookie";
import { ErrorListProps, RegistryWidgetsType } from "@rjsf/utils";
import Loader from "../../components/Loader";
import axios from "axios";
import { ROUTES } from "../../constants/routes";

let cookies = new Cookies();

const FillApplication: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<Application | null>(null);
  const [slug, setSlug] = useState<string>("");
  const [drop, setDrop] = useState(false);
  const [allSlug, setAllSlug] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>();
  const [applicationInfo, setApplicationinfo] =
    useState<ApplicationInfos | null>(null);
  const submitButtonRef = useRef<any>(null);
  const [successBox, setSuccessBox] = useState<boolean>(false);
  const [unresolved, setUnresolved] = useState<any>();
  const [boxApprove, setBoxApprove] = useState<boolean>(false);
  const [checkType, setCheckType] = useState<number>(0);

  const [showCaptcha, setShowCaptcha] = useState<any>();
  const [transactionId, setTransactionID] = useState<any>();
  const [captcha, setCaptcha] = useState<any>();
  const [showOtp, setShowOtp] = useState<any>(false);
  const [otp, setOtp] = useState<any>();
  const [aadhar, setAadhar] = useState<any>();
  const [error, setError] = useState<any>();
  const [decentCode, setDecentCode] = useState<any>();
  const [showDC, setShowDC] = useState<boolean>(false);
  const [checkAadhaar, setCheckAadhaar] = useState<boolean>(false);
  const [initAadhaar, setInitAdhaar] = useState<string>("");

  const startSessionDigi = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.digiLockerInit,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  };

  const startVerificationDigi = async () => {
    setCaptcha("");
    setOtp("");
    const data: any = await startSessionDigi();
    const response = data?.data?.data?.data?.status;
    if (response === "SUCCESS") {
      window.open(data?.data?.data?.data.data.authorizationUrl);
      setShowDC(true);
    } else {
      setError("Cannot Validate using DigiLocker");
    }
    console.log(data?.data?.data?.data.data.authorizationUrl);
  };

  const getAadhaarDetails_API = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.digiLockerCheck,
          {
            initial_decentro_txn_id: decentCode,
            aadhaar: aadhar,
            applicationInfo_id: applicationInfo?._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  };

  const getAadhaarDetails = async () => {
    const data: any = await getAadhaarDetails_API();
    const response = data?.data?.data?.data;

    if (response) {
      const avail = allSlug.indexOf(slug);
      await getNewSlugInfo(allSlug[avail]);

      setShowCaptcha(false);
      setCheckAadhaar(false);
      setShowOtp(false);
      setShowDC(false);
      alert("Aadhaar Verified");
    } else {
      setError("Cannot Validate using DigiLocker");
    }
  };

  const startSession = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.aadhaarVrfyStartRoute,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  };

  const startVerification = async () => {
    setOtp("");
    setCaptcha("");
    setError("");
    setShowOtp(false);
    setLoading(true);
    const data: any = await startSession();
    console.log({ captcha: data?.data?.data?.data?.data?.captchaImage });
    console.log({ decentroTxnId: data?.data?.data?.data?.decentroTxnId });
    setShowCaptcha(data?.data?.data?.data?.data?.captchaImage);
    setTransactionID(data?.data?.data?.data?.decentroTxnId);
    setLoading(false);
  };

  const request_aadhar_otp = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.aadhaarReqOTPRoute,
          {
            initiation_transaction_id: transactionId,
            captcha: captcha,
            aadhar: aadhar,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  };

  const start_request_aadhar_otp = async () => {
    setLoading(true);

    if (captcha) {
      const data: any = await request_aadhar_otp();
      console.log({ data: data?.data?.data?.data });

      const response = data?.data?.data?.data?.status;

      if (response === "SUCCESS") {
        setShowOtp(true);
      } else {
        setError("Invalid Captcha or aadhaar number");
      }
    }

    setLoading(false);
  };

  const verify_aadhar_otp = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.aadhaarVrfyRoute,
          {
            initiation_transaction_id: transactionId,
            otp: otp,
            applicationInfo_id: applicationInfo?._id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  };

  const start_verify_aadhar_otp = async () => {
    setLoading(true);
    const data: any = await verify_aadhar_otp();

    console.log({ data: data?.data?.data?.data });
    const response = data?.data?.data?.data.status;

    if (response === "SUCCESS") {
      const avail = allSlug.indexOf(slug);
      await getNewSlugInfo(allSlug[avail]);

      setShowCaptcha(false);
      setCheckAadhaar(false);
      setShowOtp(false);
      setShowDC(false);
      alert("Aadhaar Verified");
    } else {
      setError("Invalid OTP ");
    }
    setLoading(false);
  };

  const imgs = [
    "https://cdn.pegasus.imarticus.org/partnerimarticus/person.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/bag.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/document.svg",
  ];

  useEffect(() => {
    setLoading(true);

    const applicationLookup = async () => {
      const response = await getApplicantSideApplication();

      if (response?.status === 200) {
        let navs = response?.data?.data?.application?.app_block_schema_ids;

        let blocks = response?.data?.data;

        setApplication(blocks.application);

        blocks?.application?.app_block_schema_ids?.forEach((elem: any) => {
          if (elem.type === 2) {
            setCheckType(2);
          }

          if (elem.Edituischema.title === "Basic Details") {
            if (elem.Edituischema?.properties?.["First Name"]) {
              const prev = formData;
              const newFormData = {
                ...prev,
                "First Name": blocks.name,
                Email: blocks.email,
                "Mobile Number": blocks.mobile,
                "Country Code": blocks.ccod,
              };

              setFormData(newFormData);
            }
          }
        });

        setApplicationinfo(blocks.applicationInfo);
        setUnresolved(blocks.flags);

        const allStages = navs?.map((elem: any) => {
          return elem.stageSlug;
        });

        let lastSlug = allStages[blocks.applicationInfo.lastFilledBlockIndex];
        setSlug(lastSlug);

        if (
          lastSlug &&
          blocks?.applicationInfo?.user_response &&
          blocks?.applicationInfo?.user_response[lastSlug]
        ) {
          setFormData(blocks?.applicationInfo?.user_response[lastSlug]);
        }
        setAllSlug(allStages);

        if (formData && formData["Aadhaar Card Number"]) {
          setInitAdhaar(formData["Aadhaar Card Number"]);
        }
      }

      setLoading(false);
    };

    applicationLookup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const getNewSlugInfo = async (newSlug: string) => {
    setLoading(true);
    const response = await getApplicantSideApplication();

    if (response?.status === 200) {
      const data =
        response?.data?.data?.applicationInfo?.user_response[newSlug];
      setFormData(data);

      if (data && data["Aadhaar Card Number"]) {
        setInitAdhaar(data["Aadhaar Card Number"]);
      }
      setApplicationinfo(response?.data?.data?.applicationInfo);
    }

    setLoading(false);
  };

  const handleNext = async () => {
    submitButtonRef.current.click();
  };

  const handlePrev = () => {
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (avail > 0) {
        setSlug(allSlug[avail - 1]);
        getNewSlugInfo(allSlug[avail - 1]);
      } else {
        navigate(-1);
      }
    }
  };

  const checkPrev = () => {
    let check = true;
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (avail > 0) {
        return true;
      } else {
        return false;
      }
    }
    return check;
  };

  const checkNext = () => {
    let check = true;
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (allSlug.length - 1 > avail) {
        return true;
      } else {
        return false;
      }
    }

    return check;
  };

  const updateFormData = (e: any) => {
    let fdata = e.formData;

    setFormData(fdata);
  };

  const FinalSubmitApplication = async () => {
    setLoading(true);
    const response = await submitApplication({ id: applicationInfo?._id });

    if (response?.status === 200) {
      setSuccessBox(true);
    }

    setLoading(false);
  };

  const onSubmit = async () => {
    if (slug && allSlug?.length > 0 && formData) {
      const avail = allSlug.indexOf(slug);
      setLoading(true);
      const percent = (((avail + 1) / allSlug.length) * 100)
        .toFixed(2)
        .toString();
      console.log(formData);
      if (formData["Aadhaar Card Number"]) {
        setAadhar(formData["Aadhaar Card Number"]);
      }
      const response = await updateApplicationSlug({
        lastFilledBlockIndex: avail,
        id: applicationInfo?._id,
        user_response: { [slug]: formData },
        percent,
      });

      if (response?.status === 200) {
        setFormData({});
        setLoading(false);
        if (allSlug.length - 1 > avail) {
          getNewSlugInfo(allSlug[avail + 1]);
          setSlug(allSlug[avail + 1]);
        } else {
          if (
            checkType === 2 &&
            (!applicationInfo?.aadhaar_verified ||
              initAadhaar !== formData["Aadhaar Card Number"])
          ) {
            getNewSlugInfo(allSlug[avail]);
            setSlug(allSlug[avail]);
            setCheckAadhaar(true);
          } else {
            await FinalSubmitApplication();
          }
        }
      }
      setLoading(false);
    } else {
      setFormData({});
      setLoading(false);
    }
  };

  const ErrorListTemplate = (props: ErrorListProps) => {
    const { errors } = props;
    return (
      <div className={"error-container"}>
        <div className={"error-header"}>
          <h3 className={"error-title"}>Errors</h3>
        </div>
        <ol className={"error-list"}>
          {errors.map((error: any) => (
            <li className={"error-tile"} key={error.message}>
              {error.message}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const FileUpload = (props: any) => {
    const { value, onChange } = props;

    const [load, setLoad] = useState<boolean>(false);
    const handleChange = async (e: any) => {
      let cookies = new Cookies();
      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("aid", cookies.get("aid"));
      setLoad(true);
      const res = await uploadFormFiles(formdata);
      if (res?.status === 200) {
        props.onChange(res.data.data.url);
      } else {
        alert("Image Size Exceeded, It should be under 5MB");
      }
      setLoad(false);
    };

    return (
      <span className="uploadPic">
        {load ? (
          <Loader />
        ) : (
          <>
            <label className="customUpload" onChange={() => {}}>
              <span className="uploadtext-1">Upload</span>
              <span className="uploadtext-2">&nbsp; files here</span>

              <span className="smallText_uploadfile ml-1">
                (File size should not exceed 5 MB. Document should be in PDF,
                JPEG, JPG format only.)
              </span>
              <input
                type={"file"}
                style={{ display: "none" }}
                onChange={handleChange}
                accept="application/pdf, image/jpeg, image/jpg"
              ></input>
            </label>
            {value && (
              <div className="fileName">
                <a
                  href={value || ""}
                  target="_blank"
                  className="fileName"
                  rel="noreferrer"
                >
                  {value ? (
                    <>{new URL(value).pathname.replace("/", "")}</>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            )}

            {value && (
              <button
                className="fileRemove"
                onClick={() => {
                  onChange(undefined);
                }}
              >
                Remove
              </button>
            )}
          </>
        )}
      </span>
    );
  };

  const widgets: RegistryWidgetsType = {
    FileWidget: FileUpload,
  };

  const getNextIsProperty = (data: any) => {
    const keys = Object.keys(data);
    let dataToSend = {};
    if (keys[0] === "properties") {
      dataToSend = getNextIsProperty(data.properties);
    } else if (Object.keys(data[keys[0]])[0] === "properties") {
      dataToSend = getNextIsProperty(data[keys[0]].properties);
    } else {
      dataToSend = {
        key: keys[0],
        truthValue: data[keys[0]].const,
      };
    }
    return dataToSend;
  };

  const getValidPropertiesAsPerCondition = (editSchema: any) => {
    let dataToSend = {};
    for (let i = 0; i < editSchema.allOf.length; i++) {
      if (editSchema.allOf[i].if && formData) {
        const data: any = getNextIsProperty(editSchema.allOf[i].if);
        const toCompare: any =
          formData[data["key"]] ||
          editSchema["properties"][data["key"]].default;
        if (toCompare === data["truthValue"]) {
          dataToSend = editSchema.allOf[i].then.properties;
          break;
        }
      }
    }
    return dataToSend;
  };

  const getPropertySchema = (editSchema: any, error: any) => {
    let schemaToReturn = editSchema;
    if (error.name === "required" && error.schemaPath.includes("anyOf")) {
      // const pathArray = error.schemaPath.split('/required')
      // const lastSlashIndex = pathArray[0].lastIndexOf('/')
      // const path = pathArray[0].slice(0, lastSlashIndex)
      // const schemaPath = path.split('#/')[1]
    } else {
      if (editSchema.allOf) {
        const validProperties = getValidPropertiesAsPerCondition(editSchema);
        schemaToReturn = {
          ...editSchema,
          properties: {
            ...editSchema.properties,
            ...validProperties,
          },
        };
      }
      const filedArray = error.property.split(".");
      const field = filedArray[filedArray.length - 1];
      const schemaPropertyArray = error.schemaPath.split("#/");
      const schema = schemaPropertyArray[schemaPropertyArray.length - 1];
      const schemaPathArray = schema.split("/");
      const validation = schemaPathArray[schemaPathArray.length - 1];
      for (let i = 0; i < schemaPathArray.length - 1; i++) {
        schemaToReturn = schemaToReturn[schemaPathArray[i]];
      }
      if (validation === "required" && !schemaPathArray.includes("anyOf")) {
        schemaToReturn = schemaToReturn["properties"][field];
      }
    }

    return schemaToReturn;
  };

  const getPropertyUISchema = (uiSchema: any, error: any) => {
    let schemaToReturn = uiSchema;
    let filedArray = error.property.split(".");
    filedArray = filedArray.filter((fld: any) => {
      return fld && fld !== "";
    });
    for (let i = 0; i < filedArray.length; i++) {
      schemaToReturn = schemaToReturn[filedArray[i]];
    }
    return schemaToReturn;
  };

  const transformErrors = (errors: any, editSchema: any, uiSchema: any) => {
    // const uiSchema = FormRef.current.state.uiSchema;
    // const editSchema = block.Edituischema;

    // let duplicateError: any = [];

    return (
      errors
        // .filter((error: any) => {
        //   let schema =
        //     uiSchema[error.property.slice(1)] ||
        //     uiSchema[error.property.slice(0)];
        //   let toDisplay = true;
        //   if (
        //     schema &&
        //     schema["ui:widget"] == "file-upload" &&
        //     error.name == "type" &&
        //     error.params.type == "string"
        //   ) {
        //     toDisplay = false;
        //   }
        //   if (error.name == "anyOf" || error.name == "if") {
        //     toDisplay = false;
        //   }

        //   if (error.name == "required" && error.schemaPath.includes("anyOf")) {
        //     const pathArray = error.schemaPath.split("/required");
        //     const lastSlashIndex = pathArray[0].lastIndexOf("/");
        //     const path = pathArray[0].slice(0, lastSlashIndex);
        //     if (duplicateError.includes(path)) {
        //       toDisplay = false;
        //     } else {
        //       duplicateError.push(path);
        //     }
        //   }

        //   if (!getPropertySchema(editSchema, error)) {
        //     toDisplay = false;
        //   }

        //   return toDisplay;
        // })
        .map((error: any) => {
          if (error.property && error.schemaPath) {
            let schema = getPropertySchema(editSchema, error);
            let ui_schema = getPropertyUISchema(uiSchema, error);
            let messages = ui_schema && ui_schema["ui:messages"];
            if (error.name === "required") {
              error.message =
                messages && messages["required"]
                  ? messages["required"]
                  : `Please enter your ${schema.title}.`;
            }

            if (error.name === "pattern") {
              error.message =
                messages && messages["pattern"]
                  ? messages["pattern"]
                  : `${schema.title} should be of ${error.params.pattern} format.`;
            }

            if (error.name === "minLength") {
              error.message =
                messages && messages["minLength"]
                  ? messages["minLength"]
                  : `${schema.title} should be greater than ${error.params.limit} characters.`;
            }

            if (error.name === "maxLength") {
              error.message =
                messages && messages["maxLength"]
                  ? messages["maxLength"]
                  : `${schema.title} should be less than ${error.params.limit} characters.`;
            }

            if (error.name === "minimum") {
              error.message =
                messages && messages["minimum"]
                  ? messages["minimum"]
                  : `${schema.title} should be greater than ${error.params.limit}.`;
            }

            if (error.name === "maximum") {
              error.message =
                messages && messages["maximum"]
                  ? messages["maximum"]
                  : `${schema.title} should be less than ${error.params.limit}.`;
            }

            if (error.name === "format") {
              error.message =
                messages && messages["format"]
                  ? messages["format"]
                  : `${schema.title} should be of a proper ${error.params.format} format.`;
            }

            if (error.name === "type") {
              error.message =
                messages && messages["type"]
                  ? messages["type"]
                  : `Please enter your ${schema.title}.`;
            }
          }

          return error;
        })
    );
  };

  return (
    <div className="v_application">
      {loading || !application ? (
        <Loader />
      ) : (
        <>
          {/* <button onClick={startVerification}>Verify Aadhar</button>
        {showCaptcha ? (
          <>
            <img src={`data:image/png;base64,${showCaptcha}`} alt="abc" />
            <input
              type="string"
              value={captcha}
              onChange={(e) => {
                setCaptcha(e.target.value);
              }}
            />
            <input
              value={aadhar}
              onChange={(e) => {
                setAadhar(e.target.value);
              }}
            ></input>
            <button onClick={start_request_aadhar_otp}>Get OTP</button>

            {showOtp ? (
              <>
                <input
                  type="string"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
                <button onClick={start_verify_aadhar_otp}>Verify OTP</button>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
 */}

          {checkAadhaar && (
            <div className="popupModal">
              <div className="popupModal_main" style={{ width: "30%" }}>
                <div className="popupModal_header">
                  <span>Verify Your Aadhaar</span>
                  <img
                    src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                    alt="cross"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowCaptcha(false);
                      setCheckAadhaar(false);
                      setShowOtp(false);
                      setError("");
                      setShowDC(false);
                    }}
                  />
                </div>
                <hr />

                {error && <div className="error_aadhaar">{error}</div>}

                <div className="popupModal_body">
                  <div className="popupModal_form">
                    <div
                      className="popupModal_fields"
                      style={{ width: "100%" }}
                    >
                      <span className="popupModal_label">
                        Aadhaar Number<span className="red">*</span>
                      </span>
                      <input
                        type="text"
                        className="popupModal_input"
                        required={true}
                        value={aadhar}
                        onChange={(e) => {
                          setAadhar(e.target.value);
                        }}
                        disabled
                      />
                    </div>
                  </div>

                  {showCaptcha && !showOtp ? (
                    <>
                      <div className="popupModal_form">
                        <div
                          className="popupModal_fields"
                          style={{ width: "100%" }}
                        >
                          <img
                            src={`data:image/png;base64,${showCaptcha}`}
                            alt="abc"
                          />
                        </div>
                      </div>

                      <div className="popupModal_form">
                        <div
                          className="popupModal_fields"
                          style={{ width: "100%" }}
                        >
                          <span className="popupModal_label">
                            Enter Captcha<span className="red">*</span>
                          </span>
                          <input
                            type="text"
                            className="popupModal_input"
                            required={true}
                            value={captcha}
                            disabled={error}
                            onChange={(e) => {
                              setCaptcha(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {!error ? (
                        <div className="popupModal_form">
                          <div className="">
                            <button
                              className="pm_create "
                              onClick={start_request_aadhar_otp}
                            >
                              Get OTP
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="popupModal_form">
                          <div className="">
                            <button
                              className="pm_create"
                              style={{ width: "100%" }}
                              onClick={startVerification}
                            >
                              Retry Verification
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {showOtp ? (
                    <>
                      <div className="popupModal_form">
                        <div
                          className="popupModal_fields"
                          style={{ width: "100%" }}
                        >
                          <span className="popupModal_label">
                            Enter OTP<span className="red">*</span>
                          </span>
                          <input
                            type="text"
                            className="popupModal_input"
                            required={true}
                            value={otp}
                            disabled={error}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {!error ? (
                        <div className="popupModal_form">
                          <div className="">
                            <button
                              className="pm_create "
                              onClick={start_verify_aadhar_otp}
                            >
                              Verify OTP
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="popupModal_form">
                          <div className="">
                            <button
                              className="pm_create"
                              style={{ width: "100%" }}
                              onClick={startVerification}
                            >
                              Retry Verification
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {showDC && !showOtp && !showCaptcha && (
                    <>
                      <div className="popupModal_form">
                        <div
                          className="popupModal_fields"
                          style={{ width: "100%" }}
                        >
                          <span className="popupModal_label">
                            Enter Initial Transaction Id after DigiLocker login
                            <span className="red">*</span>
                          </span>
                          <input
                            type="text"
                            className="popupModal_input"
                            required={true}
                            value={decentCode}
                            onChange={(e) => {
                              setDecentCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="popupModal_form">
                        <div className="">
                          <button
                            className="pm_create "
                            onClick={getAadhaarDetails}
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="popupModal_foot">
                    {!showCaptcha && !decentCode && (
                      <button className="pm_create" onClick={startVerification}>
                        Verify{" "}
                      </button>
                    )}

                    {!showCaptcha && !decentCode && (
                      <button
                        className="pm_create"
                        onClick={startVerificationDigi}
                      >
                        DigiLocker
                      </button>
                    )}

                    <button
                      className="pm_create pm_cancel"
                      onClick={() => {
                        setShowCaptcha(false);
                        setCheckAadhaar(false);
                        setShowOtp(false);
                        setError("");
                        setShowDC(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {boxApprove && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <div className="errBox_Head">
                    Are you sure you want to Submit the Application?
                  </div>
                  {/* <div className="errBox_Body">
                    By approving the application it will be sent to Finance Team
                    for Review.
                  </div> */}
                  <div className="errBox_btn">
                    <button
                      className="err_no"
                      onClick={() => {
                        setBoxApprove(false);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="err_yes"
                      onClick={() => {
                        handleNext();
                        setBoxApprove(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {successBox && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tick2.svg"
                    alt="logo"
                    style={{ width: "50px" }}
                  />
                  <div className="errBox_Head mt-1">
                    Your Application has been submitted successfully!
                  </div>

                  <div style={{ textAlign: "center" }}>
                    Our Team will review the application and get back to you
                    soon
                  </div>

                  <div className="errBox_btn">
                    <button
                      className="err_yes"
                      onClick={() => {
                        setSuccessBox(false);
                        navigate("/a_app");
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* TopBar */}
          <div className="v_application_topbar">
            <img
              src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
              alt="logo"
            />
            <div
              className="topbar_profile"
              onMouseEnter={() => {
                setDrop(true);
              }}
              onMouseLeave={() => {
                setDrop(false);
              }}
            >
              <img
                src="https://cdn.pegasus.imarticus.org/images/Profile-01.svg"
                alt="profile"
                className="topbar_pic"
              />
              <span>{cookies.get("Profile")?.nam || "USER"}</span>
              <img
                src="https://cdn.pegasus.imarticus.org/labs/down-arrow.svg"
                alt="arrow"
              />
            </div>
            {drop && (
              <div
                className="topbar_drop2"
                onMouseEnter={() => {
                  setDrop(true);
                }}
                onMouseLeave={() => {
                  setDrop(false);
                }}
              >
                <div
                  onClick={() => {
                    navigate("/a_app");
                  }}
                >
                  My Applications
                </div>
                <div
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
          <div className="v_application_main">
            {/* <div
              className="v_a_back"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div> */}

            <div className="v_a_header">
              <div className="v_a_header1">APPLICATION FORM</div>
              <div className="v_a_header2">{application?.name}</div>
              <div className="v_a_header3">Help us to know more about you!</div>
            </div>

            <div className="v_topnav">
              {application &&
                application?.app_block_schema_ids.map((elem, i) => {
                  return (
                    <div className={`v_t_` + ((i % 3) + 1)} key={i}>
                      <img src={imgs[i]} alt="person" />
                      &nbsp; {elem.schemaDisplayName}
                    </div>
                  );
                })}
            </div>

            <div className="v_a_main_form">
              {application?.app_block_schema_ids.map((elem: any, i: any) => {
                return (
                  <>
                    {slug === elem.stageSlug ? (
                      <>
                        <Form
                          schema={elem.Edituischema}
                          uiSchema={elem.Viewuischema}
                          validator={validator}
                          onChange={updateFormData}
                          formData={formData}
                          key={i}
                          showErrorList={"bottom"}
                          noHtml5Validate
                          templates={{ ErrorListTemplate }}
                          onSubmit={() => {
                            onSubmit();
                          }}
                          widgets={widgets}
                          transformErrors={(errors) =>
                            transformErrors(
                              errors,
                              elem.Edituischema,
                              elem.Viewuischema
                            )
                          }
                        >
                          <button
                            ref={submitButtonRef}
                            type="submit"
                            style={{
                              display: "none",
                            }}
                          >
                            Submit
                          </button>
                        </Form>

                        <div className="form_navigation">
                          {checkPrev() ? (
                            <button className="form_back" onClick={handlePrev}>
                              Previous
                            </button>
                          ) : (
                            <span></span>
                          )}

                          {checkNext() ? (
                            <button className="form_next" onClick={handleNext}>
                              Next
                            </button>
                          ) : (
                            <button
                              className="form_next"
                              onClick={() => {
                                // setBoxApprove(true);
                                handleNext();
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>

            {unresolved && (
              <div className="v_a_header">
                <div className="v_a_header2">Flagged Remarks</div>
                <div className="v_a_header3 f_headers">Flagged Fields</div>
                <div className="flags">
                  <div className="mt-1">
                    {unresolved &&
                      unresolved.flags &&
                      unresolved.flags?.map((elem: any, i: any) => {
                        return (
                          <span
                            key={i}
                            onClick={() => {}}
                            className="flag_blocks"
                          >
                            {elem}
                          </span>
                        );
                      })}
                  </div>
                  <div className="mt-1"></div>
                  <div className="v_a_header3 mt-1 f_headers">Remarks</div>
                  <div className="f_remarks">{unresolved.comment}</div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FillApplication;
