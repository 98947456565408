import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  exportApplicationInfos,
  getAllPartnerTypes,
  getApplications,
} from "../../api/partner";
import {
  ApplicationInfos,
  FlagHistory,
  PartnerTypes,
  SalesGroup,
} from "../../types";
import { useNavigate } from "react-router-dom";
import { applicationStagetoNumber } from "../../constants/constants";
import Loader from "../Loader";
import { saveAs } from "file-saver";
import Papa from "papaparse";

interface ReviewApplicationProps {
  setSent: React.Dispatch<React.SetStateAction<number>>;
  setReview: React.Dispatch<React.SetStateAction<number>>;
}
const ReviewApplication = ({ setSent, setReview }: ReviewApplicationProps) => {
  const [totalCount, setTotalCount] = useState(0); // Total number of rows
  const [page, setPage] = useState(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFlag, setShowFlag] = useState<boolean>(false);
  const [application, setApplication] = useState<ApplicationInfos[]>([]);
  const [flags, setFlags] = useState<FlagHistory[]>();
  const [FName, setFName] = useState<string>("");
  const navigate = useNavigate();
  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");
  const [qstatus, setQstatus] = useState<string>("");
  const [qType, setQType] = useState<string>("");
  const [sOption, setsOption] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [qSG, setqSG] = useState<string>("");
  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);

  const [respList, setRespList] = useState<string[]>([]);
  const [selectionCount, setSelectionCount] = useState<number>(0);

  const handleSelection = (id: string) => {
    if (respList.includes(id)) {
      const updatedSelection = respList.filter(
        (selectedId) => selectedId !== id
      );
      setRespList(updatedSelection);
    } else {
      setRespList([...respList, id]);
    }
  };

  const handleSelectAll = () => {
    if (respList.length === selectionCount) {
      setRespList([]);
      return;
    }
    const temp: string[] = [];

    application.forEach((elem) => {
      temp.push(elem._id);
    });

    setRespList(temp);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getApplications({
        rowsPerPage,
        page,
        status: [
          applicationStagetoNumber.SUBMIT,
          applicationStagetoNumber.ADMIN_APPROVED,
          applicationStagetoNumber.FINANCE_APPROVED,
        ],
        qstatus,
        qType,
        sDate,
        eDate,
        sOption,
        search,
        qSG,
      });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setTotalCount(value.totalapplications);
        setApplication(value.applications);
        setReview(value.allReview);
        setSent(value.allSent);
        setAllSgs(value.sgNames);

        const temp = [];
        value?.applications?.forEach((elem: any) => {
          temp.push(elem?._id);
        });
        setSelectionCount(temp.length);
      }

      setLoading(false);
    };

    allTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, trigger, qType, qstatus, eDate, qSG]);

  useEffect(() => {
    setLoading(true);

    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage: 0, page: 0 });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setPartnerTypes(value.partnerTypes);
      }
    };
    allTypes();
  }, []);

  const exportSelectedApplications = async () => {
    if (respList.length === 0) {
      window.alert("Please select line items");
      return;
    }
    if (
      window.confirm(
        "Are you sure you want to export " + respList.length + " leads data"
      )
    ) {
      setLoading(true);
      const response = await exportApplicationInfos({ respList });
      if (response?.status === 200) {
        const leads = response?.data?.data?.leads;

        if (!leads || leads.length === 0) {
          alert("No leads found to export.");
          setLoading(false);
          return;
        }

        // Convert JSON to CSV
        const csv = Papa.unparse(leads);

        // Create a Blob
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        // Trigger download
        saveAs(blob, "leads_export.csv");
      } else if (response?.status === 420) {
        alert(response?.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {showFlag && (
        <div className="popupModal">
          <div className="popupModal_main">
            <div className="popupModal_header">
              <span>Flag History ({FName && FName}) </span>
              <img
                src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                alt="cross"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowFlag(false);
                }}
              />
            </div>
            <hr />
            <div className="popupModal_body">
              {flags &&
                flags.map((elem, i) => {
                  return (
                    <div key={i} className="flaghistory_div_main">
                      <div className="fh_head">Flagged {i + 1}</div>
                      <div className="flaghistory_div">
                        <div className="fh_1">
                          {elem.flags &&
                            elem.flags.map((elem1: any, i: any) => {
                              return (
                                <span key={i} className="fh_3">
                                  {elem1}
                                </span>
                              );
                            })}
                        </div>
                        <div className="fh_2">
                          {" "}
                          <b>Remarks: </b> &nbsp;{elem.comment}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      <div className="filters-4 ">
        <div>
          <div className="filter_headers">Partner Type</div>
          <select
            className="filter_inputs"
            value={qType}
            onChange={(e) => {
              setQType(e.target.value);
            }}
          >
            <option value="">All</option>

            {partnerTypes &&
              partnerTypes.map((elem, i) => {
                return <option value={elem._id}>{elem.name}</option>;
              })}
          </select>
        </div>
        <div>
          <div className="filter_headers">Sales Group</div>
          <select
            className="filter_inputs"
            value={qSG}
            onChange={(e) => {
              setqSG(e.target.value);
            }}
          >
            <option value="">All</option>

            {allSgs &&
              allSgs.length > 0 &&
              allSgs.map((elem, i) => {
                if (elem._id !== "*") {
                  return (
                    <option value={elem._id} key={i}>
                      {elem.name}
                    </option>
                  );
                } else {
                  return <></>;
                }
              })}
          </select>
        </div>
        <div>
          <div className="filter_headers">Application Review Status</div>
          <select
            className="filter_inputs"
            value={qstatus}
            onChange={(e) => {
              setQstatus(e.target.value);
            }}
          >
            <option value="">All</option>
            <option value="3">Pending</option>
            <option value="9">Approved</option>
          </select>
        </div>

        <div>
          <div className="filter_headers">Finance Review Status</div>
          <select
            className="filter_inputs"
            value={qstatus}
            onChange={(e) => {
              setQstatus(e.target.value);
            }}
          >
            <option value="">All</option>
            <option value="9">Pending</option>
            <option value="10">Approved</option>
          </select>
        </div>
        <div>
          <div className="filter_headers">From Date</div>
          <input
            value={sDate}
            onChange={(e) => {
              setsDate(e.target.value);
            }}
            type="date"
            className="filter_inputs"
          />
        </div>
        <div>
          <div className="filter_headers">To Date</div>
          <input
            type="date"
            className="filter_inputs"
            disabled={sDate ? false : true}
            value={eDate}
            onChange={(e) => {
              seteDate(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="application_header" style={{ marginTop: "1rem" }}>
        All Applications
        <Tooltip title="In Review Applications, Applications that are submitted by partner are shown here for review">
          <img
            src="https://cdn.pegasus.imarticus.org/partnerimarticus/tooltip.svg"
            alt="profile"
            className="ml-1"
          />
        </Tooltip>
      </div>
      <hr />

      <div className="search_newapp mt-1">
        <div className="flex">
          <div>
            <select
              name="cars"
              id="cars"
              className="search_filters"
              value={sOption}
              onChange={(e) => {
                setsOption(e.target.value);
              }}
            >
              <option value="">Select Search Via</option>
              <option value="UID">Search Via UID</option>
              <option value="EMAIL">Search Via Email ID</option>
              <option value="MOBILE">Search Via Mobile Number</option>
            </select>
          </div>
          <div>
            {sOption && (
              <input
                type="text"
                className="search_filters ml-1 ph-1"
                value={search}
                placeholder={`Enter ${sOption}`}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            )}

            {search && search.trim() && (
              <button
                className="form_back ml-1"
                onClick={() => {
                  setTrigger(!trigger);
                }}
              >
                Search
              </button>
            )}

            <button
              className="form_back ml-1"
              onClick={() => {
                setSearch("");
                setQType("");
                setQstatus("");
                setsDate("");
                seteDate("");
                setsOption("");
                setqSG("");
                setTrigger(!trigger);
              }}
            >
              Reset
            </button>
          </div>
        </div>

        <div className="top_btns_actions">
          <button
            className="back_btn col-green back_btn_reverse"
            onClick={() => {
              exportSelectedApplications();
            }}
          >
            Export Report
          </button>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {application && application.length > 0 ? (
            <div className="">
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 150]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <TableContainer
                component={Paper}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Table>
                  <TableHead className="table_head ">
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => {
                          handleSelectAll();
                        }}
                        checked={
                          respList.length === selectionCount &&
                          selectionCount > 0
                        }
                      />
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      S. No.
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Application Form Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner UID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Email ID
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Partner Mobile Number
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Partner Type
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Sales Group
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Application Submitted On
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Review Status (Audit)
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Review Status (Finance)
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Agreement Status
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Associate Partner
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Version
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Actions
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {application &&
                      application.map((elem, i) => {
                        return (
                          <TableRow className="table_row" key={i}>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <input
                                type="checkbox"
                                onChange={() => {
                                  handleSelection(elem._id);
                                }}
                                checked={respList.includes(elem._id)}
                              />
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {rowsPerPage * page + i + 1}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                              // className="link"
                              // onClick={() => {
                              //   navigate(
                              //     "/v_application?id=" + elem.application_id
                              //   );
                              // }}
                              >
                                {elem.p_a_name}
                              </span>
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_uid}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_name}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_email}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_mobile}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.p_type}{" "}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem.sg && elem.sg.length > 0 ? (
                                <>
                                  {elem.sg.map((sg, i) => {
                                    return (
                                      <div key={i} className="sg_users">
                                        {sg}
                                        <br />
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {elem?.submit_date &&
                                new Date(
                                  elem?.submit_date
                                ).toLocaleDateString()}
                            </TableCell>

                            <TableCell
                              className={
                                elem.application_stage === 9 ||
                                elem.application_stage === 10
                                  ? "trb approve_green"
                                  : "trb pending_yellow"
                              }
                            >
                              {elem.application_stage === 9 ||
                              elem.application_stage === 10
                                ? "Approve"
                                : "Pending"}
                            </TableCell>
                            <TableCell
                              className={
                                elem.application_stage === 10
                                  ? "trb approve_green"
                                  : "trb pending_yellow"
                              }
                            >
                              {elem.application_stage === 10
                                ? "Approve"
                                : "Pending"}
                            </TableCell>

                            <TableCell className="trb pending_yellow">
                              Pending
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {elem.parent_email ? elem.parent_email : "-"}{" "}
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              V_{elem.version}{" "}
                            </TableCell>

                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <div className="action-icons">
                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                  onClick={() => {
                                    navigate(
                                      "/r_application?id=" +
                                        elem.application_id +
                                        "&aid=" +
                                        elem.partner_aid
                                    );
                                  }}
                                />

                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/history.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                  onClick={() => {
                                    setFlags(elem.flags);
                                    setFName(elem.p_name);
                                    setShowFlag(true);
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <div className="no_application">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                alt="profile"
                className=""
              />
              <span className="na_head">It's empty here!</span>
              <span className="na_desc">Opps! Its empty here</span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReviewApplication;
