export const CountryCodes = [
  { code: "IN", value: "91", display: "India (+91)" },
  { code: "US", value: "1", display: "USA (+1)" },
  { code: "GB", value: "44", display: "UK (+44)" },
  { code: "_", value: "_", display: "Other Countries" },
  { code: "DZ", value: "213", display: "Algeria (+213)" },
  { code: "AD", value: "376", display: "Andorra (+376)" },
  { code: "AO", value: "244", display: "Angola (+244)" },
  { code: "AI", value: "1264", display: "Anguilla (+1264)" },
  { code: "AG", value: "1268", display: "Antigua &amp; Barbuda (+1268)" },
  { code: "AR", value: "54", display: "Argentina (+54)" },
  { code: "AM", value: "374", display: "Armenia (+374)" },
  { code: "AW", value: "297", display: "Aruba (+297)" },
  { code: "AU", value: "61", display: "Australia (+61)" },
  { code: "AT", value: "43", display: "Austria (+43)" },
  { code: "AZ", value: "994", display: "Azerbaijan (+994)" },
  { code: "BS", value: "1242", display: "Bahamas (+1242)" },
  { code: "BH", value: "973", display: "Bahrain (+973)" },
  { code: "BD", value: "880", display: "Bangladesh (+880)" },
  { code: "BB", value: "1246", display: "Barbados (+1246)" },
  { code: "BY", value: "375", display: "Belarus (+375)" },
  { code: "BE", value: "32", display: "Belgium (+32)" },
  { code: "BZ", value: "501", display: "Belize (+501)" },
  { code: "BJ", value: "229", display: "Benin (+229)" },
  { code: "BM", value: "1441", display: "Bermuda (+1441)" },
  { code: "BT", value: "975", display: "Bhutan (+975)" },
  { code: "BO", value: "591", display: "Bolivia (+591)" },
  { code: "BA", value: "387", display: "Bosnia Herzegovina (+387)" },
  { code: "BW", value: "267", display: "Botswana (+267)" },
  { code: "BR", value: "55", display: "Brazil (+55)" },
  { code: "BN", value: "673", display: "Brunei (+673)" },
  { code: "BG", value: "359", display: "Bulgaria (+359)" },
  { code: "BF", value: "226", display: "Burkina Faso (+226)" },
  { code: "BI", value: "257", display: "Burundi (+257)" },
  { code: "KH", value: "855", display: "Cambodia (+855)" },
  { code: "CM", value: "237", display: "Cameroon (+237)" },
  { code: "CA", value: "1", display: "Canada (+1)" },
  { code: "CV", value: "238", display: "Cape Verde Islands (+238)" },
  { code: "KY", value: "1345", display: "Cayman Islands (+1345)" },
  { code: "CF", value: "236", display: "Central African Republic (+236)" },
  { code: "CL", value: "56", display: "Chile (+56)" },
  { code: "CN", value: "86", display: "China (+86)" },
  { code: "CO", value: "57", display: "Colombia (+57)" },
  { code: "KM", value: "269", display: "Comoros (+269)" },
  { code: "CG", value: "242", display: "Congo (+242)" },
  { code: "CK", value: "682", display: "Cook Islands (+682)" },
  { code: "CR", value: "506", display: "Costa Rica (+506)" },
  { code: "HR", value: "385", display: "Croatia (+385)" },
  { code: "CU", value: "53", display: "Cuba (+53)" },
  { code: "CY", value: "90392", display: "Cyprus North (+90392)" },
  { code: "CY", value: "357", display: "Cyprus South (+357)" },
  { code: "CZ", value: "42", display: "Czech Republic (+42)" },
  { code: "DK", value: "45", display: "Denmark (+45)" },
  { code: "DJ", value: "253", display: "Djibouti (+253)" },
  { code: "DM", value: "1809", display: "Dominica (+1809)" },
  { code: "DO", value: "1809", display: "Dominican Republic (+1809)" },
  { code: "EC", value: "593", display: "Ecuador (+593)" },
  { code: "EG", value: "20", display: "Egypt (+20)" },
  { code: "SV", value: "503", display: "El Salvador (+503)" },
  { code: "GQ", value: "240", display: "Equatorial Guinea (+240)" },
  { code: "ER", value: "291", display: "Eritrea (+291)" },
  { code: "EE", value: "372", display: "Estonia (+372)" },
  { code: "ET", value: "251", display: "Ethiopia (+251)" },
  { code: "FK", value: "500", display: "Falkland Islands (+500)" },
  { code: "FO", value: "298", display: "Faroe Islands (+298)" },
  { code: "FJ", value: "679", display: "Fiji (+679)" },
  { code: "FI", value: "358", display: "Finland (+358)" },
  { code: "FR", value: "33", display: "France (+33)" },
  { code: "GF", value: "594", display: "French Guiana (+594)" },
  { code: "PF", value: "689", display: "French Polynesia (+689)" },
  { code: "GA", value: "241", display: "Gabon (+241)" },
  { code: "GM", value: "220", display: "Gambia (+220)" },
  { code: "GE", value: "7880", display: "Georgia (+7880)" },
  { code: "DE", value: "49", display: "Germany (+49)" },
  { code: "GH", value: "233", display: "Ghana (+233)" },
  { code: "GI", value: "350", display: "Gibraltar (+350)" },
  { code: "GR", value: "30", display: "Greece (+30)" },
  { code: "GL", value: "299", display: "Greenland (+299)" },
  { code: "GD", value: "1473", display: "Grenada (+1473)" },
  { code: "GP", value: "590", display: "Guadeloupe (+590)" },
  { code: "GU", value: "671", display: "Guam (+671)" },
  { code: "GT", value: "502", display: "Guatemala (+502)" },
  { code: "GN", value: "224", display: "Guinea (+224)" },
  { code: "GW", value: "245", display: "Guinea - Bissau (+245)" },
  { code: "GY", value: "592", display: "Guyana (+592)" },
  { code: "HT", value: "509", display: "Haiti (+509)" },
  { code: "HN", value: "504", display: "Honduras (+504)" },
  { code: "HK", value: "852", display: "Hong Kong (+852)" },
  { code: "HU", value: "36", display: "Hungary (+36)" },
  { code: "IS", value: "354", display: "Iceland (+354)" },
  { code: "ID", value: "62", display: "Indonesia (+62)" },
  { code: "IR", value: "98", display: "Iran (+98)" },
  { code: "IQ", value: "964", display: "Iraq (+964)" },
  { code: "IE", value: "353", display: "Ireland (+353)" },
  { code: "IL", value: "972", display: "Israel (+972)" },
  { code: "IT", value: "39", display: "Italy (+39)" },
  { code: "JM", value: "1876", display: "Jamaica (+1876)" },
  { code: "JP", value: "81", display: "Japan (+81)" },
  { code: "JO", value: "962", display: "Jordan (+962)" },
  { code: "KZ", value: "7", display: "Kazakhstan (+7)" },
  { code: "KE", value: "254", display: "Kenya (+254)" },
  { code: "KI", value: "686", display: "Kiribati (+686)" },
  { code: "KP", value: "850", display: "Korea North (+850)" },
  { code: "KR", value: "82", display: "Korea South (+82)" },
  { code: "KW", value: "965", display: "Kuwait (+965)" },
  { code: "KG", value: "996", display: "Kyrgyzstan (+996)" },
  { code: "LA", value: "856", display: "Laos (+856)" },
  { code: "LV", value: "371", display: "Latvia (+371)" },
  { code: "LB", value: "961", display: "Lebanon (+961)" },
  { code: "LS", value: "266", display: "Lesotho (+266)" },
  { code: "LR", value: "231", display: "Liberia (+231)" },
  { code: "LY", value: "218", display: "Libya (+218)" },
  { code: "LI", value: "417", display: "Liechtenstein (+417)" },
  { code: "LT", value: "370", display: "Lithuania (+370)" },
  { code: "LU", value: "352", display: "Luxembourg (+352)" },
  { code: "MO", value: "853", display: "Macao (+853)" },
  { code: "MK", value: "389", display: "Macedonia (+389)" },
  { code: "MG", value: "261", display: "Madagascar (+261)" },
  { code: "MW", value: "265", display: "Malawi (+265)" },
  { code: "MY", value: "60", display: "Malaysia (+60)" },
  { code: "MV", value: "960", display: "Maldives (+960)" },
  { code: "ML", value: "223", display: "Mali (+223)" },
  { code: "MT", value: "356", display: "Malta (+356)" },
  { code: "MH", value: "692", display: "Marshall Islands (+692)" },
  { code: "MQ", value: "596", display: "Martinique (+596)" },
  { code: "MR", value: "222", display: "Mauritania (+222)" },
  { code: "YT", value: "269", display: "Mayotte (+269)" },
  { code: "MX", value: "52", display: "Mexico (+52)" },
  { code: "FM", value: "691", display: "Micronesia (+691)" },
  { code: "MD", value: "373", display: "Moldova (+373)" },
  { code: "MC", value: "377", display: "Monaco (+377)" },
  { code: "MN", value: "976", display: "Mongolia (+976)" },
  { code: "MS", value: "1664", display: "Montserrat (+1664)" },
  { code: "MA", value: "212", display: "Morocco (+212)" },
  { code: "MZ", value: "258", display: "Mozambique (+258)" },
  { code: "MN", value: "95", display: "Myanmar (+95)" },
  { code: "NA", value: "264", display: "Namibia (+264)" },
  { code: "NR", value: "674", display: "Nauru (+674)" },
  { code: "NP", value: "977", display: "Nepal (+977)" },
  { code: "NL", value: "31", display: "Netherlands (+31)" },
  { code: "NC", value: "687", display: "New Caledonia (+687)" },
  { code: "NZ", value: "64", display: "New Zealand (+64)" },
  { code: "NI", value: "505", display: "Nicaragua (+505)" },
  { code: "NE", value: "227", display: "Niger (+227)" },
  { code: "NG", value: "234", display: "Nigeria (+234)" },
  { code: "NU", value: "683", display: "Niue (+683)" },
  { code: "NF", value: "672", display: "Norfolk Islands (+672)" },
  { code: "NP", value: "670", display: "Northern Marianas (+670)" },
  { code: "NO", value: "47", display: "Norway (+47)" },
  { code: "OM", value: "968", display: "Oman (+968)" },
  { code: "PW", value: "680", display: "Palau (+680)" },
  { code: "PA", value: "507", display: "Panama (+507)" },
  { code: "PG", value: "675", display: "Papua New Guinea (+675)" },
  { code: "PY", value: "595", display: "Paraguay (+595)" },
  { code: "PE", value: "51", display: "Peru (+51)" },
  { code: "PH", value: "63", display: "Philippines (+63)" },
  { code: "PL", value: "48", display: "Poland (+48)" },
  { code: "PT", value: "351", display: "Portugal (+351)" },
  { code: "PR", value: "1787", display: "Puerto Rico (+1787)" },
  { code: "QA", value: "974", display: "Qatar (+974)" },
  { code: "RE", value: "262", display: "Reunion (+262)" },
  { code: "RO", value: "40", display: "Romania (+40)" },
  { code: "RU", value: "7", display: "Russia (+7)" },
  { code: "RW", value: "250", display: "Rwanda (+250)" },
  { code: "SM", value: "378", display: "San Marino (+378)" },
  { code: "ST", value: "239", display: "Sao Tome &amp; Principe (+239)" },
  { code: "SA", value: "966", display: "Saudi Arabia (+966)" },
  { code: "SN", value: "221", display: "Senegal (+221)" },
  { code: "CS", value: "381", display: "Serbia (+381)" },
  { code: "SC", value: "248", display: "Seychelles (+248)" },
  { code: "SL", value: "232", display: "Sierra Leone (+232)" },
  { code: "SG", value: "65", display: "Singapore (+65)" },
  { code: "SK", value: "421", display: "Slovak Republic (+421)" },
  { code: "SI", value: "386", display: "Slovenia (+386)" },
  { code: "SB", value: "677", display: "Solomon Islands (+677)" },
  { code: "SO", value: "252", display: "Somalia (+252)" },
  { code: "ZA", value: "27", display: "South Africa (+27)" },
  { code: "ES", value: "34", display: "Spain (+34)" },
  { code: "LK", value: "94", display: "Sri Lanka (+94)" },
  { code: "SH", value: "290", display: "St. Helena (+290)" },
  { code: "KN", value: "1869", display: "St. Kitts (+1869)" },
  { code: "SC", value: "1758", display: "St. Lucia (+1758)" },
  { code: "SD", value: "249", display: "Sudan (+249)" },
  { code: "SR", value: "597", display: "Suriname (+597)" },
  { code: "SZ", value: "268", display: "Swaziland (+268)" },
  { code: "SE", value: "46", display: "Sweden (+46)" },
  { code: "CH", value: "41", display: "Switzerland (+41)" },
  { code: "SI", value: "963", display: "Syria (+963)" },
  { code: "TW", value: "886", display: "Taiwan (+886)" },
  { code: "TJ", value: "7", display: "Tajikstan (+7)" },
  { code: "TH", value: "66", display: "Thailand (+66)" },
  { code: "TG", value: "228", display: "Togo (+228)" },
  { code: "TO", value: "676", display: "Tonga (+676)" },
  { code: "TT", value: "1868", display: "Trinidad &amp; Tobago (+1868)" },
  { code: "TN", value: "216", display: "Tunisia (+216)" },
  { code: "TR", value: "90", display: "Turkey (+90)" },
  { code: "TM", value: "7", display: "Turkmenistan (+7)" },
  { code: "TM", value: "993", display: "Turkmenistan (+993)" },
  {
    code: "TC",
    value: "1649",
    display: "Turks &amp; Caicos Islands (+1649)",
  },
  { code: "TV", value: "688", display: "Tuvalu (+688)" },
  { code: "UG", value: "256", display: "Uganda (+256)" },
  { code: "UA", value: "380", display: "Ukraine (+380)" },
  { code: "AE", value: "971", display: "United Arab Emirates (+971)" },
  { code: "UY", value: "598", display: "Uruguay (+598)" },
  { code: "UZ", value: "7", display: "Uzbekistan (+7)" },
  { code: "VU", value: "678", display: "Vanuatu (+678)" },
  { code: "VA", value: "379", display: "Vatican City (+379)" },
  { code: "VE", value: "58", display: "Venezuela (+58)" },
  { code: "VN", value: "84", display: "Vietnam (+84)" },
  { code: "VG", value: "84", display: "Virgin Islands - British (+1284)" },
  { code: "VI", value: "84", display: "Virgin Islands - US (+1340)" },
  { code: "WF", value: "681", display: "Wallis &amp; Futuna (+681)" },
  { code: "YE", value: "969", display: "Yemen (North)(+969)" },
  { code: "YE", value: "967", display: "Yemen (South)(+967)" },
  { code: "ZM", value: "260", display: "Zambia (+260)" },
  { code: "ZW", value: "263", display: "Zimbabwe (+263)" },
];

export const LEADSQUARE_CENTER_LOCATIONS = [
  "Mumbai-Thane",
  "Mumbai-Andheri",
  "Navi Mumbai",
  "Pune",
  "Jaipur",
  "Delhi-NCR",
  "Gurgaon",
  "Noida",
  "Lucknow",
  "Chandigarh",
  "Dehradun",
  "Patna",
  "Bangalore",
  "Bangalore Koramangala",
  "Bangalore Marathahalli",
  "Chennai",
  "Hyderabad",
  "Coimbatore",
  "Cochin",
  "Kolkata",
  "Ahmedabad",
  "Dubai",
  "Mumbai",
  "Thane",
  "Delhi",
  "Bhubaneswar",
  "Indore",
];

export const LEADSQUARE_STATE_LOCATIONS = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Delhi",
  "Goa",
  "Gujarat",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman & Nicobar",
  "Dadra and Nagar Haveli",
  "Daman & Diu",
  "Jammu & Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
