import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  approvePartner,
  exportApplication,
  getApplicantSideApplication,
  rejectPartner,
  updateFlags,
  uploadAgreement,
  uploadFormFiles,
} from "../../api/partner";
import { Application, ApplicationInfos } from "../../types";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {
  applicationOptions,
  applicationStagetoNumber,
} from "../../constants/constants";
import Cookies from "universal-cookie";
import { RegistryWidgetsType } from "@rjsf/utils";
import Loader from "../../components/Loader";
import { checkVisibility } from "../../utility/helper";
import Process from "../../components/AdminReviewComponent/Process";

interface AdminReviewApplicationProps {
  permissions: Record<number, number[]> | undefined;
}

const AdminReviewApplication: React.FC<AdminReviewApplicationProps> = ({
  permissions,
}: AdminReviewApplicationProps) => {
  let cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [application, setApplication] = useState<Application | null>(null);
  const [slug, setSlug] = useState<string>("");
  const [allSlug, setAllSlug] = useState<string[]>([]);
  const [formData, setFormData] = useState<object | null>();

  const [applicationInfo, setApplicationinfo] =
    useState<ApplicationInfos | null>(null);
  const submitButtonRef = useRef<any>(null);
  const [options, setOptions] = useState<string>("");
  const params = new URLSearchParams(window.location.search);
  let user_aid = params.get("aid") || undefined;
  const [agreement, setAgreement] = useState<any>();
  // const [allFlags, setAllFlags] = useState<Flags[]>();
  const [comment, setComment] = useState<string>("");
  const [field, setField] = useState<string>("");
  const [selectFields, setSelectedFields] = useState<string[]>([]);
  const [allFields, setAllFields] = useState<string[]>();
  const [unresolved, setUnresolved] = useState<any>();
  const [boxFlag, setBoxFlag] = useState<boolean>(false);
  const [boxApprove, setBoxApprove] = useState<boolean>(false);
  const [boxReject, setBoxReject] = useState<boolean>(false);
  const divRef = useRef<any>();

  const imgs = [
    "https://cdn.pegasus.imarticus.org/partnerimarticus/person.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/bag.svg",
    "https://cdn.pegasus.imarticus.org/partnerimarticus/document.svg",
  ];

  useEffect(() => {
    setLoading(true);

    const applicationLookup = async () => {
      const response = await getApplicantSideApplication(user_aid);

      if (response?.status === 200) {
        let navs = response?.data?.data?.application?.app_block_schema_ids;

        let blocks = response?.data?.data;

        setApplication(blocks.application);
        setApplicationinfo(blocks.applicationInfo);
        setUnresolved(blocks.flags);

        const allStages = navs?.map((elem: any) => {
          return elem.stageSlug;
        });

        let lastSlug = allStages[blocks?.applicationInfo?.lastFilledBlockIndex];

        setSlug(lastSlug);

        console.log(
          // lastSlug
          blocks?.applicationInfo?.user_response
          // blocks?.applicationInfo?.user_response[lastSlug]
        );

        if (
          lastSlug &&
          blocks?.applicationInfo?.user_response &&
          blocks?.applicationInfo?.user_response[lastSlug]
        ) {
          setFormData(blocks?.applicationInfo?.user_response[lastSlug]);
        } else {
          alert("This form has been declared invalid");
          navigate(-1);
        }
        setAllSlug(allStages);
        const allK = [];
        for (const key in blocks?.applicationInfo?.user_response) {
          for (const key1 in blocks?.applicationInfo?.user_response[key]) {
            allK.push(key1);
          }
        }

        setAllFields(allK);
      }

      setLoading(false);
    };

    applicationLookup();
  }, [navigate, user_aid]);

  const getNewSlugInfo = async (newSlug: string) => {
    setLoading(true);
    const response = await getApplicantSideApplication(user_aid);

    if (response?.status === 200) {
      setFormData(
        response?.data?.data?.applicationInfo?.user_response[newSlug]
      );
    }

    setLoading(false);
  };

  const handleNext = async () => {
    submitButtonRef.current.click();
  };

  const handlePrev = () => {
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (avail > 0) {
        setSlug(allSlug[avail - 1]);
        getNewSlugInfo(allSlug[avail - 1]);
      } else {
        navigate(-1);
      }
    }
  };

  const checkPrev = () => {
    let check = true;
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (avail > 0) {
        return true;
      } else {
        return false;
      }
    }
    return check;
  };

  const checkNext = () => {
    let check = true;
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);

      if (allSlug.length - 1 > avail) {
        return true;
      } else {
        return false;
      }
    }

    return check;
  };

  const onSubmit = async () => {
    if (slug && allSlug?.length > 0) {
      const avail = allSlug.indexOf(slug);
      setLoading(true);

      setFormData({});
      setLoading(false);
      if (allSlug.length - 1 > avail) {
        getNewSlugInfo(allSlug[avail + 1]);
        setSlug(allSlug[avail + 1]);
      }
    }
  };

  const handleFinalDecision = async () => {
    if (options === applicationOptions.REJECT) {
      setBoxReject(true);
    } else if (options === applicationOptions.APPROVE) {
      setBoxApprove(true);
    } else if (options === applicationOptions.FLAG) {
      setBoxFlag(true);
    }
  };

  const handleFinalSubmit = async () => {
    if (options === applicationOptions.REJECT) {
      if (applicationInfo) {
        setLoading(true);
        const response = await rejectPartner({
          id: applicationInfo?._id,
          comment: comment,
        });

        if (response?.status === 200) {
          alert("Application is rejected");
          setLoading(false);
          navigate("/m_application");
        } else {
          setLoading(false);
        }
      }
    } else if (options === applicationOptions.APPROVE) {
      if (applicationInfo) {
        setLoading(true);
        const response = await approvePartner({ id: applicationInfo?._id });

        if (response?.status === 200) {
          alert("Application is Approved");
          setLoading(false);
          navigate("/m_application");
        } else {
          setLoading(false);
        }
      }
    } else if (options === applicationOptions.FLAG) {
      if (selectFields.length > 0 && comment) {
        setLoading(true);
        const response = await updateFlags({
          id: applicationInfo?._id,
          flag: selectFields,
          comment: comment,
        });

        if (response?.status === 200) {
          alert("Application is Flagged");
          setLoading(false);
          navigate("/m_application");
        }
      } else {
        alert("Please fill flags");
      }
    }
  };

  const handleFile = (e: any) => {
    let file = e.target.files[0];
    if (file === undefined) return;
    setAgreement(file);
  };

  const handleAgreement = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (window.confirm("Are you sure you want upload agreement?")) {
      const info: any = applicationInfo?._id;
      const data = new FormData();
      data.append("file", agreement);
      data.append("aid", cookies.get("aid"));
      data.append("applicationinfo_id", info);

      const response = await uploadAgreement({ data });

      if (response?.status === 200) {
        alert("Uploaded Agreement");
        setLoading(false);
        navigate("/m_application");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const FileUpload = (props: any) => {
    const { value } = props;

    const [load, setLoad] = useState<boolean>(false);
    const handleChange = async (e: any) => {
      let cookies = new Cookies();
      const formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("aid", cookies.get("aid"));
      setLoad(true);
      const res = await uploadFormFiles(formdata);
      if (res?.status === 200) {
        props.onChange(res.data.data.url);
      } else {
        alert("Image Size Exceeded, It should be under 80KB");
      }
      setLoad(false);
    };

    return (
      <span className="uploadPic">
        {load ? (
          <Loader />
        ) : (
          <>
            <label className="customUpload">
              <span className="uploadtext-1">Upload</span>
              <span className="uploadtext-2">&nbsp; files here</span>

              <span className="smallText_uploadfile ml-1">
                (File size should not exceed 5 MB. Document should be in PDF,
                JPEG, JPG format only.)
              </span>
              <input
                type={"file"}
                style={{ display: "none" }}
                onChange={handleChange}
                disabled
              ></input>
            </label>
            {value && (
              <div className="fileName">
                <a
                  href={value || ""}
                  target="_blank"
                  className="fileName"
                  rel="noreferrer"
                >
                  {value ? (
                    <>{new URL(value).pathname.replace("/", "")}</>
                  ) : (
                    <></>
                  )}
                </a>
              </div>
            )}
            {/* 
            {value && (
              <button
                className="fileRemove"
                onClick={() => {
                  onChange(undefined);
                }}
              >
                Remove
              </button>
            )} */}
          </>
        )}
      </span>
    );
  };

  const widgets: RegistryWidgetsType = {
    FileWidget: FileUpload,
  };

  return (
    <div className="v_application">
      {loading || !application ? (
        <Loader />
      ) : (
        <>
          {boxReject && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <div className="errBox_Head">
                    Are you sure you want to Reject the Application?
                  </div>
                  <div className="errBox_Body">
                    By rejecting the application the partner will be rejected
                    permanently
                  </div>
                  <div className="errBox_btn">
                    <button
                      className="err_no"
                      onClick={() => {
                        setBoxReject(false);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="err_yes"
                      onClick={() => {
                        handleFinalSubmit();
                        setBoxReject(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {boxFlag && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <div className="errBox_Head">
                    Are you sure you want to Flag the Application?
                  </div>
                  <div className="errBox_Body">
                    By flagging the application the form will be resent to
                    partner for correction
                  </div>
                  <div className="errBox_btn">
                    <button
                      className="err_no"
                      onClick={() => {
                        setBoxFlag(false);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="err_yes"
                      onClick={() => {
                        handleFinalSubmit();
                        setBoxFlag(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {boxApprove && (
            <div className="errBox_container">
              <div className="errBox_container_box">
                <div className="errBox_main">
                  <div className="errBox_Head">
                    Are you sure you want to Approve the Application?
                  </div>
                  {applicationInfo?.application_stage ===
                    applicationStagetoNumber.SUBMIT && (
                    <div className="errBox_Body">
                      By approving the application it will be sent to Finance
                      Team for Review.
                    </div>
                  )}

                  <div className="errBox_btn">
                    <button
                      className="err_no"
                      onClick={() => {
                        setBoxApprove(false);
                      }}
                    >
                      No
                    </button>
                    <button
                      className="err_yes"
                      onClick={() => {
                        handleFinalSubmit();
                        setBoxApprove(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* TopBar */}

          <div className="v_application_topbar">
            <img
              src="https://cdn.pegasus.imarticus.org/partnerimarticus/logo1.svg"
              alt="logo"
            />
          </div>
          <div className="v_application_main" ref={divRef}>
            <div
              className="v_a_back"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div>
            <div className="v_a_header">
              <div className="v_a_per">
                <div>
                  <div className="v_a_header1">APPLICATION FORM</div>
                  <div className="v_a_header2">{application?.name}</div>
                  <div className="v_a_header3">
                    Help us to know more about you!
                  </div>
                </div>
                <div className="upper_exp">
                  <span>
                    {" "}
                    <button
                      className="expbtn"
                      onClick={() => {
                        exportApplication(applicationInfo?.partner_aid);
                      }}
                    >
                      <img
                        src="https://cdn.pegasus.imarticus.org/partnerimarticus/export.svg"
                        alt="logo"
                        style={{ width: "30px" }}
                      />
                      Export Application
                    </button>
                  </span>
                  <div className="v_a_percent">
                    {applicationInfo?.percent} %{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="v_topnav">
              {application &&
                application?.app_block_schema_ids.map((elem, i) => {
                  return (
                    <div className={`v_t_` + ((i % 3) + 1)} key={i}>
                      <img src={imgs[i]} alt="person" />
                      &nbsp; {elem.schemaDisplayName}
                    </div>
                  );
                })}
            </div>
            <div className="v_a_main_form">
              {application?.app_block_schema_ids.map((elem, i) => {
                return (
                  <>
                    {slug === elem.stageSlug ? (
                      <>
                        <Form
                          schema={elem.Edituischema}
                          uiSchema={elem.Viewuischema}
                          validator={validator}
                          formData={formData}
                          key={i}
                          noHtml5Validate
                          disabled
                          widgets={widgets}
                          onSubmit={() => {
                            onSubmit();
                          }}
                        >
                          <button
                            ref={submitButtonRef}
                            type="submit"
                            style={{
                              display: "none",
                            }}
                          >
                            Submit
                          </button>
                        </Form>

                        <div className="form_navigation">
                          {checkPrev() ? (
                            <div className="exp_prev">
                              <button
                                className="form_back"
                                onClick={handlePrev}
                              >
                                Previous
                              </button>
                              <button
                                className="expbtn"
                                onClick={() => {
                                  exportApplication(
                                    applicationInfo?.partner_aid
                                  );
                                }}
                              >
                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/export.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                />
                                Export Application
                              </button>
                            </div>
                          ) : (
                            <span>
                              {" "}
                              <button
                                className="expbtn"
                                onClick={() => {
                                  exportApplication(
                                    applicationInfo?.partner_aid
                                  );
                                }}
                              >
                                <img
                                  src="https://cdn.pegasus.imarticus.org/partnerimarticus/export.svg"
                                  alt="logo"
                                  style={{ width: "30px" }}
                                />
                                Export Application
                              </button>
                            </span>
                          )}

                          {checkNext() ? (
                            <button className="form_next" onClick={handleNext}>
                              Next
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>

            {unresolved && (
              <div className="v_a_header">
                <div className="v_a_header2">Flags</div>
                <div className="v_a_header3"></div>
                <div className="flags">
                  <div className="mt-1">
                    {unresolved &&
                      unresolved.flags &&
                      unresolved.flags?.map((elem: any, i: any) => {
                        return (
                          <span
                            key={i}
                            onClick={() => {}}
                            className="flag_blocks"
                          >
                            {elem}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}

            {(applicationInfo?.application_stage ===
              applicationStagetoNumber.ADMIN_APPROVED ||
              applicationInfo?.application_stage ===
                applicationStagetoNumber.FINANCE_APPROVED ||
              applicationInfo?.application_stage ===
                applicationStagetoNumber.PROCESS_FINISHED) && (
              <div className="v_a_header">
                <div className="v_a_header2">
                  {" "}
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                    alt="logo"
                    style={{ width: "30px" }}
                  />
                  <span className="ml-1">Approved By Admin Audit team</span>
                </div>
              </div>
            )}

            {(applicationInfo?.application_stage ===
              applicationStagetoNumber.FINANCE_APPROVED ||
              applicationInfo?.application_stage ===
                applicationStagetoNumber.PROCESS_FINISHED) && (
              <div className="v_a_header">
                <div className="v_a_header2">
                  {" "}
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                    alt="logo"
                    style={{ width: "30px" }}
                  />
                  <span className="ml-1">Approved By Finance</span>
                </div>
              </div>
            )}

            {applicationInfo?.application_stage ===
              applicationStagetoNumber.PROCESS_FINISHED && (
              <div className="v_a_header">
                <div className="v_a_header2">
                  {" "}
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/tickey.svg"
                    alt="logo"
                    style={{ width: "30px" }}
                  />
                  <span className="ml-1">Agreement Uploaded</span>
                </div>
              </div>
            )}

            {applicationInfo?.application_stage ===
              applicationStagetoNumber.SUBMIT &&
            checkVisibility({ permissions: permissions, resource: 11 }) ? (
              <Process
                options={options}
                setOptions={setOptions}
                setComment={setComment}
                setField={setField}
                selectFields={selectFields}
                field={field}
                setSelectedFields={setSelectedFields}
                allFields={allFields}
                comment={comment}
                handleFinalDecision={handleFinalDecision}
              />
            ) : (
              ""
            )}

            {applicationInfo?.application_stage ===
              applicationStagetoNumber.ADMIN_APPROVED &&
            checkVisibility({ permissions: permissions, resource: 12 }) ? (
              <Process
                options={options}
                setOptions={setOptions}
                setComment={setComment}
                setField={setField}
                selectFields={selectFields}
                field={field}
                setSelectedFields={setSelectedFields}
                allFields={allFields}
                comment={comment}
                handleFinalDecision={handleFinalDecision}
              />
            ) : (
              ""
            )}

            {applicationInfo?.application_stage ===
              applicationStagetoNumber.FINANCE_APPROVED &&
              checkVisibility({ permissions: permissions, resource: 12 }) && (
                <div className="v_a_header">
                  <form onSubmit={handleAgreement}>
                    <div className="v_a_header2">
                      Upload Agreement{" "}
                      <label className="customUpload mt-1 ">
                        <span className="uploadtext-1">Upload</span>
                        <span className="uploadtext-2">
                          &nbsp; Agreement here
                        </span>

                        <span className="smallText_uploadfile ml-1"></span>
                        <input
                          type={"file"}
                          style={{ display: "none" }}
                          accept="application/pdf"
                          onChange={(e) => handleFile(e)}
                        ></input>

                        <span style={{ fontSize: "12px" }}>
                          {agreement && agreement.name}
                        </span>
                      </label>
                      {/* <input
                      type="file"
                      className="customUpload"
                      placeholder="Comments...."
                      accept="application/pdf"
                      onChange={(e) => handleFile(e)}
                      required
                    /> */}
                      <button type="submit" className="form_next ml-1 mt-1">
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminReviewApplication;
