export const sidebarMap: { [key: string]: string } = {
  TRACK_LEADS: "Track Leads",
  PARTNER_LEADS: "View Partner Leads",
  APPLICATIONS: "Application Forms",
  MANAGE_PARTNERS: "Manage Partners",
  TRACK_APPLICATION: "Track Applications",
  MANAGE_INVOICES: "Manage Invoices",
  MANAGE_SALE_GROUP: "Manage Sales Group",
  MANAGE_TYPES: "Manage Types",
  FILL_APPLICATION: "Fill Application",
  TRACK_PARTNER_SIDE_LEADS: "Track Leads",
  PARTNER_PROFILE: "Partner Profile",
  PARTNER_PORTAL: "Partner Portal",
  USER_PERMISSION: "Manage User Permissions",
  B2A_MANAGE_PARTNER: "Manage Consultant",
  B2A_REVIEW_BULK: "Review Bulk Leads",
  B2A_SINGLE_REVIEW_BULK: "Review Single Leads",
  B2A_VIEW_ALL_LEADS: "View All Leads",
  UPLOAD_LEADS: "Upload Leads",
  TRACK_C_LEADS: "Track Leads",
};

export const partnerLeadStatus: { [key: number]: string } = {
  1: "Lead Created",
  2: "Invite Sent",
  3: "Application Process Started",
  4: "Onboarded",
  5: "Not Onboarded",
};

export const emailLoginErrorMap: { [key: string]: string } = {
  NO_EMAIL: "Please Fill Email",
  NO_PASSWORD: "Please Fill Password",
  WRONG_CREDENTIALS: "Email or password you have entered seems to be incorrect",
  W_OTP: "Wrong OTP",
};

export const applicantSideStatusMap: { [key: number]: string } = {
  1: "Sent",
  2: "Pending",
  3: "Submitted",
  5: "Flagged",
  6: "Flagged",
};

export const applicationOptions: { [key: string]: string } = {
  FLAG: "Flag",
  APPROVE: "Approve",
  REJECT: "Reject",
};

export const pageOptions: { [key: string]: string } = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const applicationStage: { [key: number]: string } = {
  1: "Not Started",
  2: "In Progress",
  3: "Submitted",
  5: "Flagged: Audit Team",
  6: "Flagged: Finance Team",
  7: "Audit Team",
  8: "Finance Team",
  9: "Approved: Audit Admin",
  10: "Approve: Finance",
};

export const applicationStagetoNumber: { [key: string]: number } = {
  SENT: 1,
  FORM_FILLING: 2,
  SUBMIT: 3,
  FLAGGED_ADMIN: 5,
  FLAGGED_FINANCE: 6,
  ADMIN_APPROVED: 9,
  FINANCE_APPROVED: 10,
  PROCESS_FINISHED: 11,
};

export const maxAllowedFileSize: number = 5242880;

export const leadStatus: { [key: number]: string } = {
  2: "Invite Sent",
  4: "Onboarded",
  5: "Not Onboarded",
};

export const leadsSources: { [key: string]: string } = {
  1: "Website",
  2: "Created",
};

export const leadStages: string[] = [
  "Closed",
  "Level 6",
  "Level 5",
  "Level 3",
  "Level 3a",
  "Level 3b",
  "Level 2a",
  "Level 2",
  "Level 1a",
  "Level 1",
  "Level 0",
  "Created",
];

export const partnerStatus: { [key: number]: string } = {
  1: "Active",
  2: "Inactive",
};

export const PARTNER_OPS_ROLES: { [key: string]: string } = {
  SUPER_ADMIN: "Partner Super Admin",
  AUDIT_ADMIN: "Partner Audit Admin",
  FINANCE_MEMBER: "Partner Finance Member",
  PARTNER_TEAM_LEADERS: "Partner Team Leaders",
  APPLICANT: "Applicant",
  PARTNER: "Partner",
  B2A_ADMIN: "B2A Admin",
  B2A_TEAM_LEADER: "B2A Team Leader",
  CONSULTANT: "Consultant",
};

export const RESOURCES: { [key: number]: string } = {
  1: "ALL LEADS",
  2: "VIEW PARTNER LEADS",
  3: "APPLICATIONS",
  4: "MANAGE PARTNERS",
  5: "TRACK APPLICATIONS",
  6: "MANAGE INVOICES",
  7: "MANAGE SALE GROUP",
  8: "MANAGE TYPES",
  9: "FILL APPLICATION",
  10: "PARTNER OPS",
  11: "ADMIN APPROVAL",
  12: "FINANCE APPROVAL",
  13: "PARTNER SIDE LEADS",
  14: "USER PERMISSION",
  15: "B2A MANAGE PARTNERS",
  16: "B2A REVIEW BULK LEADS",
  17: "MASTER VIEW",
};

export const RESOURCES_PERMISSION: {
  [key: number]: string;
} = {
  1: "View",
  2: "Create",
  3: "Update",
  4: "Delete",
};

export const UPLOAD_STATUS: {
  [key: number]: string;
} = {
  1: "Pending",
  2: "Uploaded",
  3: "Not Uploaded",
  4: "Partial",
};

export const REQ_STATUS: {
  [key: number]: string;
} = {
  1: "Pending",
  2: "Approved",
  3: "Rejected",
};
