import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import "./index.css";
import PartnerLeads from "./pages/Dashboard/PartnerLeads";
import TrackLeads from "./pages/Dashboard/TrackLeads";
import Application from "./pages/Dashboard/Application";
import ManagePartners from "./pages/Dashboard/ManagePartners";
import TrackApplication from "./pages/Dashboard/TrackApplication";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { getPartnerPermission } from "./api/partner";
import { checkDeepVisibility, checkVisibility } from "./utility/helper";
import ManageSaleGroup from "./pages/Dashboard/ManageSaleGroup";
import ManageTypes from "./pages/Dashboard/ManageTypes";
import ViewSingleApplication from "./pages/Dashboard/ViewSingleApplication";
import Dashboard from "./pages/Dashboard/Dashboard";
import FillApplication from "./pages/Applicant/FillApplication";
import AllPartnerSideApplication from "./pages/Applicant/AllPartnerSideApplication";
import AdminReviewApplication from "./pages/Dashboard/AdminReviewApplication";
import EditApplication from "./components/Application/EditApplication";
import PartnerSideLeads from "./pages/Applicant/PartnerSideLeads";
import PartnerProfile from "./pages/Dashboard/PartnerProfile";
import Master from "./pages/Dashboard/Master";
import UserPermission from "./pages/Dashboard/UserPermission";
import ManageB2APartners from "./pages/Dashboard/B2A/B2AManagePartners";
import ReviewBulk from "./pages/Dashboard/B2A/ReviewBulk";
import TrackLeadsB2A from "./pages/Dashboard/B2A/TrackLeads";
import ConsultantProfile from "./pages/Dashboard/B2A/ConsultantProfile";
import UploadLeads from "./pages/Dashboard/B2A/UploadLeads";
import TrackLeadsConsultantB2A from "./pages/Dashboard/B2A/TrackConsultantLeads";
import B2AViewAllLeads from "./pages/Dashboard/B2A/ViewAllLeads";
import SingleReview from "./pages/Dashboard/B2A/SingleReview";

let cookies = new Cookies();

const App: React.FC = () => {
  const [permissions, setPermissions] = useState<Record<number, []>>();
  const navigate = useNavigate();

  useEffect(() => {
    let isLoggedIn = cookies.get("isLoggedIn");

    if (!isLoggedIn) {
      navigate("/");
    } else {
      const permissionCheck = async (): Promise<void> => {
        await getPartnerPermission({ setPermissions: setPermissions });
      };
      permissionCheck();
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route
        path="/dashboard"
        element={<Dashboard permissions={permissions} />}
      />

      {checkVisibility({ permissions: permissions, resource: 17 }) && (
        <Route path="/master" element={<Master permissions={permissions} />} />
      )}

      {checkVisibility({ permissions: permissions, resource: 14 }) && (
        <Route
          path="/user_permission"
          element={<UserPermission permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 1 }) && (
        <Route
          path="/trackLeads"
          element={<TrackLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 2 }) && (
        <Route
          path="/partnerLeads"
          element={<PartnerLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 3 }) && (
        <Route
          path="/application"
          element={<Application permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 3 }) && (
        <Route
          path="/e_app"
          element={<EditApplication permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 4 }) && (
        <Route
          path="/v_application"
          element={<ViewSingleApplication permissions={permissions} />}
        />
      )}

      {/* {checkVisibility({ permissions: permissions, resource: 4 }) && (
        <Route path="/hide" element={<Hidden permissions={permissions} />} />
      )} */}

      {checkVisibility({ permissions: permissions, resource: 5 }) && (
        <Route
          path="/r_application"
          element={<AdminReviewApplication permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 4 }) && (
        <Route
          path="/m_partner"
          element={<ManagePartners permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 1 }) && (
        <Route
          path="/partnerProfile"
          element={<PartnerProfile permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 5 }) && (
        <Route
          path="/m_application"
          element={<TrackApplication permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 7 }) && (
        <Route
          path="/m_s_grp"
          element={<ManageSaleGroup permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 8 }) && (
        <Route
          path="/m_typ"
          element={<ManageTypes permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 9 }) && (
        <Route path="/fill_application" element={<FillApplication />} />
      )}

      {checkVisibility({ permissions: permissions, resource: 9 }) && (
        <Route
          path="/a_app"
          element={<AllPartnerSideApplication permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 13 }) && (
        <Route
          path="/track_o_leads"
          element={<PartnerSideLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 13 }) && (
        <Route
          path="/track_o_leads"
          element={<PartnerSideLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 15 }) && (
        <Route
          path="/m_b2a_partner"
          element={<ManageB2APartners permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 16 }) && (
        <Route
          path="/r_bulk"
          element={<ReviewBulk permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 16 }) && (
        <Route
          path="/r_single"
          element={<SingleReview permissions={permissions} />}
        />
      )}

      {checkDeepVisibility({
        permissions: permissions,
        value: 2,
        resource: 15,
      }) && (
        <Route
          path="/v_a_l"
          element={<B2AViewAllLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 15 }) && (
        <Route
          path="/track_b2_leads"
          element={<TrackLeadsB2A permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 15 }) && (
        <Route
          path="/cProfile"
          element={<ConsultantProfile permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 18 }) && (
        <Route
          path="/u_leads"
          element={<UploadLeads permissions={permissions} />}
        />
      )}

      {checkVisibility({ permissions: permissions, resource: 19 }) && (
        <Route
          path="/track_c_leads"
          element={<TrackLeadsConsultantB2A permissions={permissions} />}
        />
      )}

      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default App;
