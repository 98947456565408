import { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import TopBar from "../../components/Topbar";
import { partnerStatus, sidebarMap } from "../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getAllPartnerTypes, getAllPartners } from "../../api/partner";
import { PartnerTypes, Partners, SalesGroup } from "../../types";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import { getDate } from "../../utility/helper";

interface ManagePartnersProps {
  permissions: Record<number, number[]> | undefined;
}

const ManagePartners: React.FC<ManagePartnersProps> = ({
  permissions,
}: ManagePartnersProps) => {
  const [active, setActive] = useState(sidebarMap.MANAGE_PARTNERS);
  const [loading, setLoading] = useState<boolean>(false);
  const [partners, setPartners] = useState<Partners[]>([]);
  const [sOption, setsOption] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [trigger, setTrigger] = useState<boolean>(false);
  const [qtype, setQtype] = useState<string>("");
  const [qstatus, setQstatus] = useState<string>("");
  const [partnerTypes, setPartnerTypes] = useState<PartnerTypes[]>([]);
  const [qSG, setqSG] = useState<string>("");
  const [allSgs, setAllSgs] = useState<SalesGroup[]>([]);

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const toFind: any = {
        rowsPerPage,
        page,
        qstatus,
        qtype,
        sOption,
        search,
        qSG,
      };

      const response = await getAllPartners(toFind);
      if (response) {
        const value = response?.data?.data;
        setTotalCount(value?.totalPartners);
        setPartners(value?.partners);
        setAllSgs(value.sgNames);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, trigger, qtype, qstatus, qSG]);

  useEffect(() => {
    const allTypes = async () => {
      const response = await getAllPartnerTypes({ rowsPerPage: 0, page: 0 });

      if (response?.status === 200) {
        let value = response?.data?.data;

        setPartnerTypes(value.partnerTypes);
      }
    };
    allTypes();
  }, []);

  return (
    <div>
      <SideBar
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="application_header">All Partners</div>
          <hr />

          <div className="search_newapp mt-1">
            <div className="flex">
              <div>
                <select
                  name="cars"
                  id="cars"
                  value={sOption}
                  className="search_filters"
                  onChange={(e) => {
                    setsOption(e.target.value);
                  }}
                >
                  <option value="">Select Search Via</option>
                  <option value="UID">Search Via UID</option>
                  <option value="EMAIL">Search Via Email ID</option>
                  <option value="MOBILE">Search Via Mobile Number</option>
                </select>
              </div>
              <div>
                {sOption && (
                  <input
                    type="text"
                    className="search_filters ml-1 ph-1"
                    placeholder={`Enter ${sOption}`}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                )}

                {search && search.trim() && (
                  <button
                    className="form_back ml-1"
                    onClick={() => {
                      setTrigger(!trigger);
                    }}
                  >
                    Search
                  </button>
                )}
                <button
                  className="form_back ml-1"
                  onClick={() => {
                    setSearch("");
                    setQtype("");
                    setsOption("");
                    setQstatus("");
                    setTrigger(!trigger);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="filters-2">
              <div>
                <div className="filter_headers">Partner Status</div>
                <select
                  onChange={(e) => setQstatus(e.target.value)}
                  value={qstatus}
                  className="filter_inputs"
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </select>
              </div>
              <div className="ml-1">
                <div className="filter_headers">Partner Type</div>
                <select
                  onChange={(e) => {
                    setQtype(e.target.value);
                  }}
                  value={qtype}
                  className="filter_inputs"
                >
                  <option value="">All</option>
                  {partnerTypes &&
                    partnerTypes.map((elem, i) => {
                      return (
                        <option value={elem._id} key={i}>
                          {elem.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="ml-1">
                <div className="filter_headers">Sales Group</div>
                <select
                  className="filter_inputs"
                  value={qSG}
                  onChange={(e) => {
                    setqSG(e.target.value);
                  }}
                >
                  <option value="">All</option>

                  {allSgs &&
                    allSgs.length > 0 &&
                    allSgs.map((elem, i) => {
                      if (elem._id !== "*") {
                        return (
                          <option value={elem._id} key={i}>
                            {elem.name}
                          </option>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                </select>
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {partners && partners.length > 0 ? (
                <div className="">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    <Table>
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          UID
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Partner Name{" "}
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Partner Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Parter Mobile No.
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Partner Type
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Sales Group
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Associated Partner Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Lead Source
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Created On
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px" }}
                        >
                          Action
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {partners &&
                          partners.map((elem, i) => {
                            let viewLink = "";
                            viewLink += "?id=" + elem._id + "&aid=" + elem.aid;

                            if (elem?.details?.lead_source) {
                              viewLink +=
                                "&lsource=" + elem.details?.lead_source;
                            } else if (elem?.parent_email) {
                              viewLink += "&email=" + elem?.parent_email;
                            } else {
                              viewLink += "&email=" + elem?.p_email;
                            }
                            return (
                              <TableRow className="table_row" key={i}>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {rowsPerPage * page + i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.p_uid}{" "}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.p_name}{" "}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_mobile}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.p_type}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.sg && elem.sg.length > 0 ? (
                                    <>
                                      {elem.sg.map((sg, i) => {
                                        return (
                                          <div key={i} className="sg_users">
                                            {sg}
                                            <br />
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.parent_email ? elem.parent_email : "-"}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {elem.details?.lead_source
                                    ? elem.details?.lead_source
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {getDate(elem.createdAt)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                  className={
                                    elem.details.status === 1
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                >
                                  {partnerStatus[elem.details.status]}{" "}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  <Link to={`/trackLeads` + viewLink}>
                                    View Profile
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">
                    Click on Create Partner to map the existing leads with a
                    partner
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePartners;
