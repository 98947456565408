import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import {
  REQ_STATUS,
  sidebarMap,
  UPLOAD_STATUS,
} from "../../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { getAllB2aLeads } from "../../../api/partner";
import { LeadRequestsB2A } from "../../../types";

import Loader from "../../../components/Loader";
import SideBarB2A from "../../../components/SidebarB2A";
import moment from "moment-timezone";

interface B2AViewAllLeadsProps {
  permissions: Record<number, number[]> | undefined;
}

const B2AViewAllLeads: React.FC<B2AViewAllLeadsProps> = ({
  permissions,
}: B2AViewAllLeadsProps) => {
  const [active, setActive] = useState(sidebarMap.B2A_VIEW_ALL_LEADS);
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<LeadRequestsB2A[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const toFind: any = {
        rowsPerPage,
        page,
        search,
      };

      const response = await getAllB2aLeads(toFind);
      if (response) {
        const value = response?.data?.data;

        setTotalCount(value?.totalDraftLeadsCount);
        setRequests(value?.totalDraftLeads);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, trigger]);

  return (
    <div>
      <SideBarB2A
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="application_header">All Uploaded Leads</div>
          <hr />

          <div className="search_newapp mt-1">
            <div className="flex">
              <div>
                <input
                  type="text"
                  className="search_filters ml-1 ph-1"
                  placeholder={`Enter Email, Mobile No.`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                {search && search.trim() && (
                  <button
                    className="form_back ml-1"
                    onClick={() => {
                      setTrigger(!trigger);
                    }}
                  >
                    Search
                  </button>
                )}

                {search && search.trim() && (
                  <button
                    className="form_back ml-1"
                    onClick={() => {
                      setSearch("");

                      setTrigger(!trigger);
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {requests && requests.length > 0 ? (
                <div className="">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                    sx={{ maxHeight: "550px" }}
                  >
                    <Table
                      className="table_p"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead className="table_head">
                        <TableCell
                          className="trb trb_fix_1"
                          align="center"
                          style={{
                            minWidth: "10px",
                            background: "#eeeeef",
                            zIndex: "4",
                          }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb trb_fix_2"
                          align="center"
                          style={{
                            minWidth: "100px",
                            background: "#eeeeef",
                            zIndex: "4",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className="trb trb_fix_3"
                          align="center"
                          style={{
                            minWidth: "200px",
                            background: "#eeeeef",
                            zIndex: "4",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Country Code
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Mobile
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Program
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Team Leader Name
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Team Leader Email
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Consultant Name
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Consultant Email
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Consultant Phone
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Consultant UID
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Lead Import Date
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Source
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Lead Stage <br />
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Approval Status
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Upload Status
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {requests &&
                          requests.map((elem, i) => {
                            return (
                              <TableRow key={i} className="table_row">
                                <TableCell
                                  className="trb trb_fix_1"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {rowsPerPage * page + i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb trb_fix_2"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Name}
                                </TableCell>
                                <TableCell
                                  className="trb trb_fix_3"
                                  align="center"
                                  style={{ minWidth: "200px" }}
                                >
                                  {elem?.Email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Ccod}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Mobile}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Program}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Team_Leader_Name}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Team_Leader_Email}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Consultant_Name}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Consultant_Email}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Consultant_Phone}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Consultant_UID}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Lead_Import_Date?.value
                                    ? moment
                                        .utc(elem?.Lead_Import_Date?.value)
                                        .tz("Asia/Kolkata")
                                        .format("DD-MM-YY, HH:mm:ss")
                                    : "N/A"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.Lead_Source}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem?.Lead_Stage}
                                </TableCell>

                                <TableCell
                                  className={
                                    elem?.Approval_Status === 1
                                      ? "trb pending_yellow"
                                      : elem?.Approval_Status === 2
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {REQ_STATUS[elem?.Approval_Status]}
                                </TableCell>

                                <TableCell
                                  className={
                                    elem?.Upload_Status === 1
                                      ? "trb pending_yellow"
                                      : elem?.Upload_Status === 2
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {UPLOAD_STATUS[elem?.Upload_Status]}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">
                    Click on Create Partner to map the existing leads with a
                    partner
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default B2AViewAllLeads;
