import { useEffect, useState } from "react";
import TopBar from "../../../components/Topbar";
import {
  REQ_STATUS,
  sidebarMap,
  UPLOAD_STATUS,
} from "../../../constants/constants";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  getCRequests,
  getRequestedLeads,
  updateRequest,
} from "../../../api/partner";
import {
  BConsultants,
  BLeaders,
  LeadRequests,
  SingleRequestLeads,
} from "../../../types";

import Loader from "../../../components/Loader";
import SideBarB2A from "../../../components/SidebarB2A";
import moment from "moment-timezone";

interface SingleReviewProps {
  permissions: Record<number, number[]> | undefined;
}

const SingleReview: React.FC<SingleReviewProps> = ({
  permissions,
}: SingleReviewProps) => {
  const [active, setActive] = useState(sidebarMap.B2A_SINGLE_REVIEW_BULK);
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<LeadRequests[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [totalCount1, setTotalCount1] = useState<number>(0); // Total number of rows
  const [page1, setPage1] = useState<number>(0); // Current page number
  const [rowsPerPage1, setRowsPerPage1] = useState<number>(10);

  const [trigger, setTrigger] = useState<boolean>(false);
  const [leaders, setLeaders] = useState<BLeaders[]>([]);
  const [consultants, setConsultants] = useState<BConsultants[]>([]);
  const [singleLeads, setSingleLeads] = useState<boolean>(false);
  const [sLeads, setsLeads] = useState<SingleRequestLeads[]>();

  const [remarks, setRemarks] = useState<string>("");
  const [activeRequest, setActiveRequest] = useState<string>("");
  const [actReqdata, setActReqData] = useState<LeadRequests>();

  const [qstatus, setQstatus] = useState<string>();
  const [qleader, setQleader] = useState<string>();
  const [qconsultant, setQconsultant] = useState<string>();
  const [sDate, setsDate] = useState<string>("");
  const [eDate, seteDate] = useState<string>("");

  const [programName, setProName] = useState<string>("");
  const [owner, setOwner] = useState<string>("");

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage1: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0); // Reset page number when rows per page changes
    getSingleRequestLeads(activeRequest, "0", parseInt(event.target.value, 10));
  };

  const handleChangePage1 = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage1(newPage);
    getSingleRequestLeads(activeRequest, newPage, rowsPerPage);
  };

  useEffect(() => {
    setLoading(true);
    const getPartners = async () => {
      const toFind: any = {
        rowsPerPage,
        page,
        qstatus,
        qleader,
        qconsultant,
        sDate,
        eDate,
        single: true,
      };

      const response = await getCRequests(toFind);
      if (response) {
        const value = response?.data?.data;
        setTotalCount(value?.totalRequests);
        setRequests(value?.requests);
        setLeaders(value?.leaders);
        setConsultants(value?.consultants);
      }

      setLoading(false);
    };

    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, trigger, qstatus, qleader, qconsultant, eDate]);

  const getSingleRequestLeads = async (
    id: string,
    page: any,
    rowsPerPage: number
  ) => {
    setLoading(true);

    const response = await getRequestedLeads(
      id,
      rowsPerPage || rowsPerPage1,
      page || page1
    );

    if (response?.status === 200) {
      const value = response.data.data;
      setSingleLeads(true);
      setsLeads(value.leads);
      setActiveRequest(id);
      setActReqData(value.req);
      setTotalCount1(value.totalLeads);
      setProName(value.req.program);
      setOwner(value.owner);
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }

    setLoading(false);
  };

  const updateRequestSingle = async (action: string) => {
    setLoading(true);

    const response = await updateRequest(remarks, activeRequest, action);

    if (response?.status === 200) {
      // const value = response.data.data;
      setTrigger(!trigger);
      setSingleLeads(false);
      setRemarks("");
    } else if (response?.status === 420) {
      alert(response?.data.message);
    }

    setLoading(false);
  };

  return (
    <div>
      <SideBarB2A
        setActive={setActive}
        active={active}
        permissions={permissions}
      />
      <TopBar active={active} />
      <div className="main">
        <div className="main_application">
          <div className="application_header">All Single Requests</div>
          <hr />

          <div className="filters-4">
            <div>
              <div className="filter_headers">Team Leader</div>
              <select
                className="filter_inputs"
                value={qleader}
                onChange={(e) => {
                  setQleader(e.target.value);
                }}
              >
                <option value="">All</option>

                {leaders &&
                  leaders.map((elem, i) => {
                    return (
                      <option key={i} value={elem.aid}>
                        {elem.p_name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div>
              <div className="filter_headers">Lead Created By</div>
              <select
                className="filter_inputs"
                value={qconsultant}
                onChange={(e) => {
                  setQconsultant(e.target.value);
                }}
              >
                <option value="">All</option>

                {consultants &&
                  consultants.map((elem, i) => {
                    return (
                      <option key={i} value={elem.aid}>
                        {elem.p_name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div>
              <div className="filter_headers">Select Status</div>
              <select
                className="filter_inputs"
                value={qstatus}
                onChange={(e) => {
                  setQstatus(e.target.value);
                }}
              >
                <option value="">All</option>
                <option value="1">Pending </option>
                <option value="2">Approved</option>
                <option value="3">Rejected</option>
              </select>
            </div>
            <div>
              <div className="filter_headers">From Date</div>
              <input
                value={sDate}
                onChange={(e) => {
                  setsDate(e.target.value);
                }}
                type="date"
                className="filter_inputs"
              />
            </div>
            <div>
              <div className="filter_headers">To Date</div>
              <input
                type="date"
                className="filter_inputs"
                value={eDate}
                disabled={sDate ? false : true}
                onChange={(e) => {
                  seteDate(e.target.value);
                }}
              />
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {singleLeads && (
                <div className="popupModal">
                  <div
                    className="popupModal_main"
                    style={{
                      maxHeight: "80vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="popupModal_header">
                      <span>All Leads </span>
                      <img
                        src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                        alt="cross"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSingleLeads(false);
                        }}
                      />
                    </div>
                    <hr />
                    <div
                      className="popupModal_body"
                      style={{
                        width: "90%",
                        padding: "1rem",

                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 150]}
                        component="div"
                        count={totalCount1}
                        rowsPerPage={rowsPerPage1}
                        page={page1}
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage1}
                      />

                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: "550px" }}
                      >
                        <Table
                          className="table_p"
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead className="table_head">
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              {" "}
                              S.No
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Country Code
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Mobile No.
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Program Domain
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Lead Owner
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Lead Source
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Lead Stage
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Approval Status
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{
                                minWidth: "100px",
                                background: "#eeeeef",
                              }}
                            >
                              Lead Import Status
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {sLeads &&
                              sLeads.map((elem, i) => {
                                return (
                                  <TableRow className="table_row">
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {" "}
                                      {i + 1}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.firstName}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.email}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.ccod ? "+ " + elem.ccod : ""}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.mobile}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {programName}
                                    </TableCell>{" "}
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {owner}
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      B2A Consultant
                                    </TableCell>
                                    <TableCell
                                      className="trb"
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {elem.stage}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        elem.status === 1
                                          ? "trb pending_grey"
                                          : elem.status === 2
                                          ? "trb approve_green"
                                          : "trb flagged_red"
                                      }
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {REQ_STATUS[elem.status]}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        elem.upload_status === 1
                                          ? "trb pending_grey"
                                          : elem.upload_status === 2
                                          ? "trb approve_green"
                                          : elem.upload_status === 3
                                          ? "trb flagged_red"
                                          : "trb pending_yellow"
                                      }
                                      align="center"
                                      style={{ minWidth: "100px" }}
                                    >
                                      {UPLOAD_STATUS[elem.upload_status]}
                                    </TableCell>
                                    {/* <TableCell className="trb" align="center" style={{ minWidth: "100px" }}>
                                      {elem.remarks ? elem.remarks : "-"}
                                    </TableCell> */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                    {actReqdata && actReqdata.status === 1 && (
                      <>
                        <div className="remarks_bulk">
                          <div className="popupModal_label">Overall Remark</div>

                          <textarea
                            className="overall_remarks"
                            onChange={(e) => {
                              setRemarks(e.target.value);
                            }}
                          />
                        </div>

                        <div className="popupModal_foot">
                          <button
                            className="pm_create pm_cancel"
                            onClick={() => {
                              setSingleLeads(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="pm_create pm_reject"
                            onClick={() => {
                              updateRequestSingle("REJECTED");
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className="pm_create"
                            onClick={() => {
                              updateRequestSingle("APPROVE");
                            }}
                          >
                            Approve
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {requests && requests.length > 0 ? (
                <div className="">
                  <div>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100, 150]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>

                  <TableContainer
                    component={Paper}
                    style={{ width: "100%", marginTop: "1rem" }}
                    sx={{ maxHeight: "550px" }}
                  >
                    <Table
                      className="table_p"
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead className="table_head">
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          {" "}
                          S.No
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Program Domain
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Team Leader
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Lead Created By
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Created Time
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Number of Leads
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Approval Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Lead Import Status
                        </TableCell>
                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Remarks
                        </TableCell>

                        <TableCell
                          className="trb"
                          align="center"
                          style={{ minWidth: "100px", background: "#eeeeef" }}
                        >
                          Action
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {requests &&
                          requests.map((elem, i) => {
                            return (
                              <TableRow className="table_row">
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {" "}
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.program}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.leader_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.createdBy_email}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {moment
                                    .utc(elem.createdAt)
                                    .tz("Asia/Kolkata")
                                    .format("DD-MM-YY, HH:mm:ss")}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.count}
                                </TableCell>
                                <TableCell
                                  className={
                                    elem.status === 1
                                      ? "trb pending_yellow"
                                      : elem.status === 2
                                      ? "trb approve_green"
                                      : "trb flagged_red"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {REQ_STATUS[elem.status]}
                                </TableCell>
                                <TableCell
                                  className={
                                    elem.upload_status === 1
                                      ? "trb pending_grey"
                                      : elem.upload_status === 2
                                      ? "trb approve_green"
                                      : elem.upload_status === 3
                                      ? "trb flagged_red"
                                      : "trb pending_yellow"
                                  }
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {UPLOAD_STATUS[elem.upload_status]}
                                </TableCell>
                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  {elem.remarks ? elem.remarks : "-"}
                                </TableCell>

                                <TableCell
                                  className="trb"
                                  align="center"
                                  style={{ minWidth: "100px" }}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                    alt="eye2"
                                    onClick={async () => {
                                      await getSingleRequestLeads(
                                        elem._id,
                                        page1,
                                        rowsPerPage1
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="no_application">
                  <img
                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                    alt="profile"
                    className=""
                  />
                  <span className="na_head">It's empty here!</span>
                  <span className="na_desc">
                    Click on Create Partner to map the existing leads with a
                    partner
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleReview;
