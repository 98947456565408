import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAllApplications } from "../../api/partner";
import { Application } from "../../types";
import { useNavigate } from "react-router-dom";
import { pageOptions } from "../../constants/constants";
import Loader from "../Loader";
import { getDate } from "../../utility/helper";

interface ViewApplicationProps {
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

const ViewAllApplication = ({ setTab }: ViewApplicationProps) => {
  const [totalCount, setTotalCount] = useState<number>(0); // Total number of rows
  const [page, setPage] = useState<number>(0); // Current page number
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [trigger, setTrigger] = useState<boolean>(false);

  const handleChangeRowsPerPage: React.ChangeEventHandler<{
    value: unknown;
  }> = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when rows per page changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setLoading(true);
    const allApplications = async () => {
      const toFind: any = {
        rowsPerPage,
        page,
      };

      const response = await getAllApplications(toFind);
      if (response) {
        let value = response?.data?.data;
        setTotalCount(value.totalapplications);
        setApplications(value.applications);
      }
      setLoading(false);
    };
    allApplications();
  }, [page, rowsPerPage, trigger]);

  const allApplications = async () => {
    const toFind: any = {
      rowsPerPage,
      page,
    };

    if (search.trim()) {
      toFind["search"] = search;
    }
    const response = await getAllApplications(toFind);
    if (response) {
      let value = response?.data?.data;
      setTotalCount(value.totalapplications);
      setApplications(value.applications);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="partner_leads_header">
        <span>All Applications Forms</span>
      </div>
      <hr />

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-1 head_bars">
            <div>
              <input
                type="text"
                value={search}
                className="search_filters ml-1 ph-1"
                placeholder={`Search`}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <button
                className="form_back ml-1"
                onClick={() => {
                  allApplications();
                }}
              >
                Search
              </button>

              <button
                className="form_back ml-1"
                onClick={() => {
                  setSearch("");
                  setTrigger(!trigger);
                }}
              >
                Reset
              </button>
            </div>

            <div>
              <button
                onClick={() => {
                  setTab(pageOptions.CREATE);
                }}
                className="back_btn col-green"
              >
                + Create Applications Form
              </button>
            </div>
          </div>
          {applications.length > 0 ? (
            <>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <TableContainer
                component={Paper}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Table>
                  <TableHead className="table_head">
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      S. No
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Name
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      {" "}
                      Version
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Last Modified{" "}
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Created On{" "}
                    </TableCell>
                    <TableCell
                      className="trb"
                      align="center"
                      style={{ minWidth: "100px" }}
                    >
                      Actions
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {applications.length > 0 &&
                      applications.map((elem, i) => {
                        return (
                          <TableRow className="table_row" key={i}>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {rowsPerPage * page + i + 1}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {elem.name}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              V_{elem.version}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {getDate(elem.updatedAt)}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              {" "}
                              {getDate(elem.createdAt)}
                            </TableCell>
                            <TableCell
                              className="trb"
                              align="center"
                              style={{ minWidth: "100px" }}
                            >
                              <div className="action-btns">
                                <button
                                  className=""
                                  style={{
                                    border: "none",
                                    background: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/v_application?id=" + elem._id);
                                  }}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                    alt="bin"
                                  />
                                </button>
                                <button
                                  style={{
                                    border: "none",
                                    cursor: "pointer",
                                    padding: "4px",
                                    borderRadius: "2px",
                                  }}
                                  onClick={() => {
                                    navigate("/e_app?id=" + elem._id);
                                  }}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/partnerimarticus/pencil.svg"
                                    alt="bin"
                                  />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <div className="no_application">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                alt="profile"
                className=""
              />
              <span className="na_head">It's empty here!</span>
              <span className="na_desc">No Application Found</span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewAllApplication;
